import {Component, OnInit} from '@angular/core';
import {BlockAuthService} from "../../../services/block-auth.service";

@Component({
  selector: 'app-color-picker-premium-settings',
  templateUrl: './color-picker-premium-settings.component.html',
  styleUrls: ['./color-picker-premium-settings.component.css']
})
export class ColorPickerPremiumSettingsComponent implements OnInit {
  isLoadingAuth: boolean;
  authError: string;

  constructor(private blockAuthService: BlockAuthService) { }

  ngOnInit(): void {
    const sub = this.blockAuthService.getAuthToken().subscribe(() => {

        this.isLoadingAuth = false;

    }, error => {
      this.authError = error.error_description;
      this.isLoadingAuth = false;
    });
    setTimeout(() => {
      this.isLoadingAuth = false;
      sub.unsubscribe();
    }, 6000);
  }

}
