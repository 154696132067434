<!--<div class="slds-form-element">-->
<!--  <label class="slds-form-element__label" for="text-input-id-1">Palette Name</label>-->
<!--  <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">-->
<!--    <svg class="slds-icon slds-input__icon slds-input__icon_right slds-icon-text-default" aria-hidden="true">-->
<!--      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#color_swatch"></use>-->
<!--    </svg>-->
<!--    <input type="text" id="text-input-id-1" placeholder="Color Palette Name" class="slds-input" />-->
<!--  </div>-->
<!--</div>-->
<ngl-alert *ngIf="authError" class="slds-theme_alert-texture" variant="error" iconName="error" (close)="this.authError = null">
  <h2>{{this.authError}}</h2>
</ngl-alert>

<div class="slds-m-around_medium">
  <div *ngIf="accountPlan !== SubscriptionPlanType.PREMIUM">
  <div class="slds-text-body_regular slds-m-top_medium">{{accountPlan === SubscriptionPlanType.PREMIUM ? paletteName: "Custom colors (up to ten)"}}</div>
  </div>
  <app-palette-name-editor *ngIf="accountPlan === SubscriptionPlanType.PREMIUM" [(paletteName)]="paletteName"></app-palette-name-editor>
    <div  *ngIf="isLoadingColors" style="position:relative; height:6.25rem; z-index:0;">
    <ngl-spinner size="small" variant="brand" alternativeText="Loading"></ngl-spinner>
  </div>
  <div *ngIf="!isLoadingColors">
    <div  *ngFor="let color of textColorsSwatches; let i = index" >
      <div class="flex">
        <ngl-colorpicker class="inline-block" [(ngModel)]="hexColors[i]" [label]="null"
                         variant="custom"></ngl-colorpicker>

        <div class="inline-block slds-align-content-center slds-m-top_medium slds-m-left_x-small">
          <a (click)="toggleColorTypeSelected('text_background',color)"> <!--  TODO add borders around buttons-->
            <span [ngClass]="{'btn-activated':color.text_types.includes('text_background')}"
              class="slds-button_icon slds-button_icon-border-filled  color-type-selector-button btn-activated "
              title="Select Text Color Background">
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#text_background_color"></use>
              </svg>
              <span class="slds-assistive-text">Select Text Color Background</span>
            </span>
          </a>
          <a (click)="toggleColorTypeSelected('text_color',color)">
            <span [ngClass]="{'btn-activated':color.text_types.includes('text_color')}"
              class=" slds-button_icon slds-button_icon-border-filled color-type-selector-button"
              title="Select Text Color">
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#text_color"></use>
              </svg>
              <span class="slds-assistive-text">Select Text Color</span>
            </span>
          </a>
          <button (click)="deleteColor(color,hexColors[i])"
            class=" delete-color-button slds-button slds-button_icon slds-button_icon-border-filled"
            title="Delete Color">
            <svg class="slds-button__icon" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete"></use>
            </svg>
            <span class="slds-assistive-text">Delete Color</span>
          </button>
        </div>
      </div>
      <div *ngIf="this.isColorInValid(hexColors[i])" class="slds-text-heading_small slds-m-top_xxx-small">
        <span class="slds-text-color_error">
          <ng-container>Please do not save duplicate colors</ng-container>
        </span>
      </div>
    </div>
  </div>

  <button  [attr.disabled]="(accountPlan !== SubscriptionPlanType.PREMIUM)&&(textColorsSwatches.length  >= 10) ? '' : null" [attr.title]="(accountPlan !== SubscriptionPlanType.PREMIUM)&&(textColorsSwatches.length  < 10)? '' : 'Reached limit of how many colors can be added'"
    class="slds-button slds-button_brand add-color-button slds-m-vertical_small" (click)="addColor()">Add a color
  </button>
  <div>
    <button class="slds-button slds-button_outline-brand slds-m-right_medium" routerLink="../colorpickersettings">Cancel
    </button>
    <button class="slds-button slds-button_brand " (click)="saveColorPalette(); isSavingColors = true;">
      <div *ngIf="isSavingColors" style="position:relative; height:1.87rem; width: 2.87rem; z-index:0;">
        <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
      </div>
      <div *ngIf="!isSavingColors">Save</div>
    </button>
  </div>
</div>
