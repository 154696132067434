<div  *ngIf="isLoadingAuth" style="position:relative;height: 4.3rem;z-index:0;">
  <div class="slds-spinner_container">
    <div class="slds-spinner slds-spinner_medium slds-spinner_brand" role="status">
      <span class="slds-assistive-text">Loading</span>
      <div class="slds-spinner__dot-a"></div>
      <div class="slds-spinner__dot-b"></div>
    </div>
  </div>
</div>
<div>
  <div *ngFor="let colorPalette of textColors">
    <div [queryParams]="{cp_id: colorPalette.id}" class="slds-text-body_regular slds-m-top_small" routerLink="../colorpalette">{{colorPalette.palette_name}}<ngl-icon class="slds-m-left_x-small" iconName="utility:edit" size="xx-small"></ngl-icon></div>
    <div class="color-display-org slds-m-top_x-small">
      <div class="org-color-display-text-color color-display slds-m-top_x-small">
        <span class="slds-icon_container slds-icon-utility-text-color " title="Text Color">
          <svg aria-hidden="true" class="slds-icon slds-icon-text-default slds-icon_small">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#text_color"></use>
          </svg>
          <span class="slds-assistive-text">Text Color</span>
        </span>
        <div class="text-color-swatches text-swatches">
          <span *ngFor="let textColor of filterTextColorType(colorPalette,'text_color')" class="slds-swatch slds-m-horizontal_xx-small" style="background:rgb({{textColor.r}}, {{textColor.g}}, {{textColor.b}})">
            <span class="slds-assistive-text">rgb({{textColor.r}}, {{textColor.g}}, {{textColor.b}})</span>
          </span>
        </div>
      </div>
      <div class="org-color-display-text-color-background color-display slds-m-top_x-small">
        <span class="slds-icon_container slds-icon-utility-text-background" title="Text Color Background">
          <svg aria-hidden="true" class="slds-icon slds-icon-text-default slds-icon_small">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#text_background_color"></use>
          </svg>
          <span class="slds-assistive-text">Text Color Background</span>
        </span>
        <div class="text-color-background-swatches text-swatches">
          <span *ngFor="let textColor of filterTextColorType(colorPalette,'text_background') " class="slds-swatch slds-m-horizontal_xx-small" style="background:rgb({{textColor.r}}, {{textColor.g}}, {{textColor.b}})">
            <span class="slds-assistive-text">rgb({{textColor.r}}, {{textColor.g}}, {{textColor.b}})</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
