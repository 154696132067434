import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';




@Component({
  selector: 'app-palette-name-editor',
  templateUrl: './palette-name-editor.component.html',
  styleUrls: ['./palette-name-editor.component.css']
})
export class PaletteNameEditorComponent implements OnInit {
  isPopupVisible: boolean;
  @Input() paletteName: string;
  @Output() paletteNameChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  submitName(): void {
    this.isPopupVisible = false;
    this.paletteNameChange.emit(this.paletteName);
  }
}
