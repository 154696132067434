<div class="content-block">
  <span class="large-Number ml3 slds-text-color--inverse slds-text-heading_large">1</span>
  <span class="small-des slds-text-color--inverse slds-text-heading_large">Create an installed
    package{{continued ? '...continued' : ''}}</span>
</div>
<div *ngIf="!continued" class="part-1">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Go to Setup</span>
        <img src="/assets/images/color-picker/setup/step-1-1.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Go to Apps > Installed Packages</span>
        <img src="/assets/images/color-picker/setup/step-1-2.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Click on "New"</span>
        <img src="/assets/images/color-picker/setup/step-1-3.png">
      </div>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large">
    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>
<div *ngIf="continued" class="part-2">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Name the package "MCToolbox-ColorPicker", then press save.</span>
        <img src="/assets/images/color-picker/setup/step-1-1-1.png">
      </div>
    </div>
  </div>

  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
    <button (click)="continued = !continued" class="mr-auto block slds-button slds-button_brand">Previous</button>

    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>
