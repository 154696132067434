import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ColorPickerEditorComponent} from './installed-app/color-picker-editor/color-picker-editor.component';
import {HomeSplashComponent} from './home-splash/home-splash.component';
import {ColorPickerSettingsHomeComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-settings-home.component';
import {ColorPickerPalettesComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-palettes/color-picker-palettes.component';
import {ColorPickerPaymentComponent} from './installed-app/color-picker-settings/color-picker-payment/color-picker-payment.component';
import {InstalledAppComponent} from './installed-app/installed-app.component';
import {ColorPickerPaymentCancelComponent} from './installed-app/color-picker-settings/color-picker-payment-cancel/color-picker-payment-cancel.component';
import {ColorPickerInstallComponent} from './color-picker-install/color-picker-install.component';
import {ColorPickerSetupP1Component} from './color-picker-install/color-picker-setup-p1/color-picker-setup-p1.component';
import {ColorPickerSetupP3Component} from './color-picker-install/color-picker-setup-p3/color-picker-setup-p3.component';
import {ColorPickerSetupP2Component} from './color-picker-install/color-picker-setup-p2/color-picker-setup-p2.component';
import {ColorPickerSetupP4Component} from './color-picker-install/color-picker-setup-p4/color-picker-setup-p4.component';
import {ColorPickerSetupP5Component} from './color-picker-install/color-picker-setup-p5/color-picker-setup-p5.component';
import {ColorPickerPremiumSettingsComponent} from './installed-app/color-picker-settings/color-picker-premium-settings/color-picker-premium-settings.component';
import {PaletteNameEditorComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-palettes/palette-name-editor/palette-name-editor.component';
import {MergeFieldSettingsComponent} from "./installed-app/color-picker-settings/color-picker-premium-settings/merge-field-settings/merge-field-settings.component";
import {PremiumFontSettingsComponent} from "./installed-app/color-picker-settings/color-picker-premium-settings/premium-font-settings/premium-font-settings.component";
import {PreDefinedHeadingSettingsComponent} from "./installed-app/color-picker-settings/color-picker-premium-settings/pre-defined-heading-settings/pre-defined-heading-settings.component";


const routes: Routes = [
  {path: 'IA/:installedAppId', component: InstalledAppComponent, children: [
      { path: 'colorpicker', component: ColorPickerEditorComponent },
      { path: 'colorpickersettings', component: ColorPickerSettingsHomeComponent },
      { path: 'colorpickerpremiumsetting', component: ColorPickerPremiumSettingsComponent },
      { path: 'colorpickerpremiumsetting/fonts', component: PremiumFontSettingsComponent },
      { path: 'colorpickerpremiumsetting/merge-fields', component: MergeFieldSettingsComponent },
      { path: 'colorpickerpremiumsetting/pre-defined-headings', component: PreDefinedHeadingSettingsComponent },
      { path: 'colorpalette', component: ColorPickerPalettesComponent },
      { path: 'colorpayment', component: ColorPickerPaymentComponent},
      { path: 'colorpayment/cancel', component: ColorPickerPaymentCancelComponent}
    ]},
  { path: 'colorpicker', component: ColorPickerInstallComponent },

  { path: 'colorpicker/setup/1', component: ColorPickerSetupP1Component },
  { path: 'colorpicker/setup/2', component: ColorPickerSetupP2Component },
  { path: 'colorpicker/setup/3', component: ColorPickerSetupP3Component },
  { path: 'colorpicker/setup/4', component: ColorPickerSetupP4Component },
  { path: 'colorpicker/setup/5', component: ColorPickerSetupP5Component },

  { path: '', component: HomeSplashComponent }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
