<div class="slds-form-element">
  <label class="slds-form-element__label" for="card-input">
    Card Number</label>
  <div class="slds-form-element__control">
    <ngx-stripe-card id="card-input" class="slds-input"
                     [options]="cardOptions"
                     [elementsOptions]="elementsOptions"
    ></ngx-stripe-card>
  </div>
</div>

<fieldset class="slds-form-element ">
  <label class=" slds-form-element__label">Select a plan</label>
  <div class="slds-form-element__control " style="display: flex">
    <div class="slds-visual-picker slds-visual-picker_small ml-auto" (click)="planType = SubscriptionPlanType.BASIC">
      <input type="radio" id="visual-picker-100" value="visual-picker-100" name="options" [checked]="planType === SubscriptionPlanType.BASIC"/>
      <label for="visual-picker-100">
        <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
          <span>
            <span class="slds-text-heading_large">$5</span>
            <span class="slds-text-title">USD/user/month *</span>
          </span>
        </span>
        <span class="slds-visual-picker__body">
    <span class="slds-text-heading_small">Standard plan</span>
    <span class="slds-text-title slds-m-top_small slds-text-color--inverse-weak">Billed monthly</span>
    <span class="slds-text-title slds-text-color--inverse-weak">$5 per user</span>
    <span class="slds-text-title slds-text-color--inverse-weak">Basic plan up to 1 color palette with up to 10 brand colors</span>
        </span>
        <span class="slds-icon_container slds-visual-picker__text-check">
          <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
          </svg>
        </span>
      </label>
    </div>
    <div class="slds-visual-picker slds-visual-picker_small mr-auto" (click)="planType = SubscriptionPlanType.PREMIUM">
      <input type="radio" id="visual-picker-101" value="visual-picker-101" name="options" [checked]="planType === SubscriptionPlanType.PREMIUM"/>
      <label for="visual-picker-101">
        <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
          <span>
            <span class="slds-text-heading_large">$10</span>
            <span class="slds-text-title">USD/user/month *</span>
          </span>
        </span>
        <span class="slds-visual-picker__body">
           <span class="slds-text-heading_small">Premium plan</span>
    <span class="slds-text-body_regular slds-m-top_small slds-text-color--inverse-weak">Billed monthly</span>
    <span class="slds-text-body_regular slds-text-color--inverse-weak">$10 per user</span>
    <span
      class="slds-text-body_regular slds-text-color--inverse-weak">Premium plan with unlimited colors and palettes</span>
        </span>
        <span class="slds-icon_container slds-visual-picker__text-check">
          <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
          </svg>
        </span>
      </label>
    </div>
  </div>
</fieldset>
<div class="slds-text-title"> Users (all contentent editors in your BU need a license, <a href="javascript:void(0);">see terms</a>):</div>
<div class="slds-form-element ">
  <label class="slds-form-element__label slds-m-right_none" for="numberSeats">Seats</label>
  <div class="slds-form-element__control">
    <button class="slds-button slds-button_icon slds-button_icon-small slds-input__button_decrement" (click)="updateQuantity(-1)" title="Decrement counter">
      <svg class="slds-button__icon" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#ban"></use>
      </svg>
      <span class="slds-assistive-text">Decrement counter</span>
    </button>
    <input type="number" id="numberSeats" placeholder="100" class="slds-input slds-input_counter"  [(ngModel)]="this.quantity" value="10"/>
    <button class="slds-button slds-button_icon slds-button_icon-small slds-input__button_increment"  (click)="updateQuantity(1)"  title="Increment counter">
      <svg class="slds-button__icon" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new"></use>
      </svg>
      <span class="slds-assistive-text">Increment counter</span>
    </button>
  </div>
</div>


<button class="slds-button slds-button_brand slds-m-top_medium"  type="submit" (click)="createToken()">
  <div *ngIf="this.isSavingAccountDetails" style="position:relative; height:1.87rem; width: 2.3rem; z-index:0;">
    <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
  </div>
  <div *ngIf="!this.isSavingAccountDetails">Submit (you agree to pay ${{quantity*getPrice()}}/month)</div>
</button>
<div *ngIf="this.accountError" class="slds-text-heading_small slds-m-top_xxx-small">
        <span class="slds-text-color_error">
          <ng-container>{{this.accountError}}</ng-container>
        </span>
</div>

