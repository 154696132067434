import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {InstalledAppService} from '../services/installed-app.service';

@Component({
  selector: 'app-installed-app',
  templateUrl: './installed-app.component.html',
  styleUrls: ['./installed-app.component.css']
})
export class InstalledAppComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void{
    this.sub = this.route.params.subscribe(params => {
      InstalledAppService.installedAppId = params.installedAppId; // Called before any components are rendered
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
