<form [formGroup]="this.accountForm">

  <div class="slds-grid slds-gutters">
    <div class="slds-col">


      <div [class]="{'slds-has-error': firstName.invalid && (firstName.dirty || firstName.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="firstname">First Name</label>
        <div class="slds-form-element__control">
          <input type="text" id="firstname" formControlName="firstName" required placeholder="First Name" [attr.disabled]="this.isSavingAccountDetails?  '':null"
                 [attr.aria-describedby]="firstName.invalid && (firstName.dirty || firstName.touched)? 'form-error-02': null"
                 [attr.aria-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)? 'true': null"
                 class="slds-input"/>  <!-- TODO fix disabled error -->
        </div>
        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="slds-form-element__help"
             id="form-error-02">

          <div *ngIf="firstName.errors.required">
            First Name is required.
          </div>
          <div *ngIf="firstName.errors.minlength">
            Name must be at least 4 characters long.
          </div>
          <div *ngIf="firstName.errors.forbiddenName">
            Name cannot be Bob.
          </div>
        </div>
      </div>
    </div>
    <div class="slds-col">
      <div [class]="{'slds-has-error': lastName.invalid && (lastName.dirty || lastName.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="lastName">Last Name</label>
        <div class="slds-form-element__control">
          <input type="text" id="lastName" formControlName="lastName" required placeholder="Last name" [attr.disabled]="this.isSavingAccountDetails? '':null"
                 [attr.aria-describedby]="lastName.invalid && (lastName.dirty || lastName.touched)? 'form-error-01': null"
                 [attr.aria-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)? 'true': null"
                 class="slds-input"/>
        </div> <!-- TODO fix disabled error -->
        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="slds-form-element__help"
             id="form-error-01">

          <div *ngIf="lastName.errors.required">
            Last Name is required.
          </div>
          <div *ngIf="lastName.errors.minlength">
            Last Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [class]="{'slds-has-error': email.invalid && (email.dirty || email.touched)}"
       class="slds-form-element slds-has-error">
    <label class="slds-form-element__label" for="email">Email</label>
    <div class="slds-form-element__control">
      <input type="text" id="email" formControlName="email" required placeholder="Email" [attr.disabled]="this.isSavingAccountDetails ? '':null"
             [attr.aria-describedby]="email.invalid && (email.dirty || email.touched)? 'form-error-03': null"
             [attr.aria-invalid]="email.invalid && (email.dirty || email.touched)? 'true': null"
             class="slds-input"/> <!-- TODO fix disabled error -->
    </div>
    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="slds-form-element__help"
         id="form-error-03">

      <div *ngIf="email.errors.required">
        Email is required.
      </div>
      <div *ngIf="email.errors.email">
        Must be a valid email
      </div>

    </div>
  </div>
  <div class="web-box">


    <button (click)="saveAccountDetails()" [attr.disabled]="accountForm.invalid? '':null"  [attr.title]="accountForm.valid? 'Next Step' : 'Must input valid fields' " class="slds-button slds-button_brand slds-m-top_medium slds-m-right_medium ">
      <div *ngIf="this.isSavingAccountDetails" style="position:relative; height:1.87rem; width: 2.3rem; z-index:0;">
        <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
      </div>
      <div *ngIf="!this.isSavingAccountDetails">Next</div>
    </button>
    <div></div>
    <div *ngIf="this.accountError" class="slds-text-heading_small  slds-m-top_large ">
        <span class="slds-text-color_error">
          <ng-container>{{this.accountError}}</ng-container>
        </span>
    </div>
    <button class="slds-button slds-button_destructive  slds-m-top_medium  ml-auto block"  routerLink="../colorpickersettings" title="cancel">Cancel</button>

  </div>
</form>
