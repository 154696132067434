export interface ColorSwatch {
  id: string;
  text_types: string[];
  r: number;
  g: number;
  b: number;
}
export function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (x: number) => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
export function getQuillColors(unfilteredColors: ColorSwatch[], type: string): string[]{
  const quillColors = [];
  unfilteredColors.filter(x => x.text_types.includes(type)).forEach((value, index, array) => {
    quillColors.push(rgbToHex(value.r, value.g, value.b));
  });
  return quillColors;
}
