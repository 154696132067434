export interface PaymentState {
  activePaymentPage: PaymentPage;
  paymentPageStates: PaymentPageState;
}

export type PaymentPageState = {
  [completionState in PaymentPage]: CompletionState;
};
export enum CompletionState {
  INCOMPLETE= 'INCOMPLETE',
  CURRENT= 'CURRENT',
  COMPLETE= 'COMPLETE',
  ACTIVE= 'ACTIVE',
  ERROR= 'ERROR'
}
export enum PaymentPage {
  CREATE,
  BILLING,
  PAY
}

