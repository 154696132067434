<div *ngIf="isLoadingEditor" class="demo-only" style="height:6rem">
  <div class="slds-spinner_container">
    <div class="slds-spinner slds-spinner_large slds-spinner_brand" role="status">
      <span class="slds-assistive-text">Loading</span>
      <div class="slds-spinner__dot-a"></div>
      <div class="slds-spinner__dot-b"></div>
    </div>
  </div>
</div>
<ngl-alert *ngIf="authError" class="slds-theme_alert-texture" variant="error" iconName="error" (close)="this.authError = null">
  <h2>{{this.authError}}</h2>
</ngl-alert>
<article *ngIf="!isInIframe && !isLoadingEditor" class="slds-card">
  <div class="slds-card__header slds-grid">
    <header class="slds-media slds-media_center slds-has-flexi-truncate">
      <div class="slds-media__figure">
        <span class="slds-icon_container slds-icon-standard-canvas" title="Color Picker">
          <svg class="slds-icon slds-icon_small" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#canvas"></use>
          </svg>
        </span>
      </div>
      <div class="slds-media__body">
        <h2>
          <div routerLink="/" class="slds-card__header-link slds-truncate">
            <span class="slds-text-heading_small">Color Picker Editor</span>
          </div>
        </h2>
      </div>
    </header>
  </div>
  <div class="slds-card__body slds-card__body_inner">
    <div id="workspace"></div>
    <footer class="slds-card__footer">This application is for use in the Salesforce Marketing Cloud Content Builder
      Editor only.
    </footer>
  </div>
</article>

<ngl-alert *ngIf="this.showNoAccountAlert || this.noLicenceAlert" variant="info" assistiveText="No Account" iconName="info" (close)="onClose($event); this.showNoAccountAlert = false;">
  <h2 class="slds-text-heading_small">
    It looks like you don't have a Color Picker account. Create one now by clicking on the settings tab above
  </h2>
</ngl-alert>

<quill-editor #editor (onContentChanged)="editorContentChanged($event)" (onEditorCreated)="getEditor($event)"  *ngIf="(isInIframe || !isEnvironmentProduction ) && !isLoadingEditor" [(ngModel)]="content" [modules]="toolbarOptions"></quill-editor>
