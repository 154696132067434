import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {IRegion, STATES} from '../../types/us-states';
import {countries} from 'src/app/types/countries.enum';
import {StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {BillingDetails} from '../../installed-app/color-picker-settings/color-picker-payment/billing-details/billing-details';
import {BillingAccountCreationInfo, PaymentService} from '../../services/payment.service';
import {InstallStateService} from '../install-state.service';
import {SubscriptionPlanType} from '../../types/plan-type.enum';

@Component({
  selector: 'app-color-picker-setup-p2',
  templateUrl: './color-picker-setup-p2.component.html',
  styleUrls: ['./color-picker-setup-p2.component.css']
})
export class ColorPickerSetupP2Component implements OnInit {
  continued: boolean;
  accountForm: FormGroup;
  isSavingAccountDetails: boolean;
  isStripeCardInfoInvalid = true;

  constructor(
    private stripeService: StripeService,
    private router: Router,
    private paymentService: PaymentService,
    private activatedRoute: ActivatedRoute,
    private installService: InstallStateService,
  ) {
  }

  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;

  billingInfoForm: FormGroup;
  accountError: string;
  billingDetails: BillingDetails;


  states: IRegion[] = STATES;
  countries: IRegion[] = countries;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#706E6B',
        color: '#353535',
        fontWeight: '300',
        fontFamily: '"Salesforce Sans",Arial,sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  quantity: number;
  planType: SubscriptionPlanType;
  SubscriptionPlanType = SubscriptionPlanType;

  ngOnInit(): void {
    this.installService.getPaymentStateLocationAndUpdate(2);
    this.accountForm = new FormGroup({
      firstName: new FormControl( this.installService.billingAccount ?  this.installService.billingAccount.firstName : '', [
        Validators.required,
        Validators.minLength(4),
      ]),
      lastName: new FormControl(this.installService.billingAccount ?  this.installService.billingAccount.lastName : '', [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl(this.installService.billingAccount ?  this.installService.billingAccount.email : '' , [
        Validators.required,
        Validators.email
      ]),
    });
    this.billingInfoForm = new FormGroup({
      full_name: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      address: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      state: new FormControl('', [
        Validators.required,
      ]),
      city: new FormControl('', [
        Validators.required,
      ]),
      zip: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      country: new FormControl('', [
        Validators.required,
      ]),
    });
    this.planType = this.installService.planType;
    this.quantity = this.installService.quantity;
  }

  get firstName(): AbstractControl {
    return this.accountForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.accountForm.get('lastName');
  }

  get emailAccount(): AbstractControl {
    return this.accountForm.get('email');
  }

  get full_name(): AbstractControl {
    return this.billingInfoForm.get('full_name');
  }

  get address(): AbstractControl {
    return this.billingInfoForm.get('address');
  }

  get email(): AbstractControl {
    return this.billingInfoForm.get('email');
  }

  get state(): AbstractControl {
    return this.billingInfoForm.get('state');
  }

  get city(): AbstractControl {
    return this.billingInfoForm.get('city');
  }

  get zip(): AbstractControl {
    return this.billingInfoForm.get('zip');
  }

  get country(): AbstractControl {
    return this.billingInfoForm.get('country');
  }

  nextButtonClicked(): void {
    if (this.continued === true) {
      this.createToken();
    } else {
      this.continued = true;
    }
  }

  previousButtonClicked(): void {
    if (this.continued === true) {
      this.continued = false;
    } else {
      this.router.navigate(['../1'], {relativeTo: this.activatedRoute});
    }
  }

  onChange(event: StripeCardElementChangeEvent): void {
    if (event.error) {
      this.accountError = event.error.message;
      this.isStripeCardInfoInvalid = true;
    } else {
      this.accountError = '';
      if (event.complete){
        this.isStripeCardInfoInvalid = false;
      }
    }
  }

  updateQuantity(quantity: number): void {
    this.quantity += quantity;
  }


  createToken(): void {
    this.billingDetails = {
      full_name: this.full_name.value,
      address_ln_1: this.address.value,
      email: this.email.value,
      state: this.state.value,
      city: this.city.value,
      zip: this.zip.value,
      country: this.country.value,
    };
    const accountInfo: BillingAccountCreationInfo = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value
    };
    this.installService.billingAccount = accountInfo;
    this.installService.planType = this.planType;
    this.accountError = '';
    this.stripeService
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
        billing_details: {
          address: {
            city: this.billingDetails.city,
            country: this.billingDetails.country,
            line1: this.billingDetails.address_ln_1,
            postal_code: this.billingDetails.zip,
            state: this.billingDetails.state,
          },
          email: this.billingDetails.email,
          name: this.billingDetails.full_name,
        },
      }).subscribe(value => {
      if (value.paymentMethod) {
        //  Payment Method Retrieved
        this.installService.quantity = this.quantity;
        this.installService.paymentMethod = value.paymentMethod;
        this.router.navigate(['../3'], {relativeTo: this.activatedRoute});

      } else if (value.error) {
        this.accountError = value.error.message;
        // Error creating token
        console.log(`Error: ${value.error.message}`);
      }
    }, error => {
      this.accountError = error.error.message;
    });

  }


}
