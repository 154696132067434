<div class="slds-spinner_container" *ngIf="this.processingInformation">
<ngl-spinner size="large" alternativeText="Processing transaction" variant="brand">

</ngl-spinner>
</div>
<div class="content-block">
  <span class="large-Number ml3 slds-text-color--inverse slds-text-heading_large">5</span>
  <span class="small-des slds-text-color--inverse slds-text-heading_large">{{ paymentProcessed ? 'Billing confirmation' :'Confirm your account billing'}}</span>
</div>
<div *ngIf="!paymentProcessed" class="slds-m-around--x-large ">
  <div class="slds-text-heading_small slds-m-top_medium">
    Your business unit has {{quantity}} users. Each user with content editor permissions needs a license. Any user with
    admin permissions in your BU can cancel at any time via the settings tab of the app, or by contacting <a
    href="mailto:info@e8enterprizes.com">info@e8enterprizes.com</a>.
    <br/>
    <br/>


    By purchasing this app you agree to let E8 Enterprizes, LLC store your name, email address, API access tokens for
    your users, and Business Unit ID. You also agree to let Stripe process your credit card information.
  </div>
  <div class="center  mt3 ">
    <div *ngIf="planType === 'basic'" class="slds-visual-picker slds-visual-picker_large " style="width: 49%;">
      <input type="radio" id="visual-picker-101" value="visual-picker-101" name="options" readonly=""
             checked="checked"/>
      <label for="visual-picker-101">
        <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
          <span class="slds-m-around_large">

            <span class="slds-text-heading_large super-large-number">$5</span>
            <span class="slds-text-title">per user per month</span>

          </span>
          <span>
            <span class="slds-text-heading_medium">The standard plan includes:</span>
            <ul class="slds-text-align--left" style="list-style: circle; margin-left: 2rem;">
              <li>$5/user/month</li>
              <li>10 custom colors</li>
              <li>Custom text colors</li>
              <li>Custom background colors</li>
              <li>QuillJS editor</li>
              <li>Merge fields</li>
              <li>Tables</li>
            </ul>
          </span>
        </span>

        <span class="slds-icon_container slds-visual-picker__text-check">
          <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
          </svg>
        </span>
      </label>
    </div>


      <div *ngIf="planType === 'premium'" class="slds-visual-picker slds-visual-picker_large " style="width: 49%;">
        <input type="radio" id="visual-picker-102" value="visual-picker-102" name="options" readonly=""
               checked="checked"/>
        <label for="visual-picker-102">
          <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
            <span class="slds-m-around_large">

              <span class="slds-text-heading_large super-large-number">$10</span>
              <span class="slds-text-title">per user per month</span>

            </span>
            <span>
              <span class="slds-text-heading_medium">The premium plan includes:</span>
              <ul class="slds-text-align--left" style="list-style: circle; margin-left: 2rem;">
                <li>$10/user/month</li>
                <li>Unlimited color palettes</li>
                <li>Unlimited custom colors</li>
                <li>Custom text colors</li>
                <li>Custom Background colors</li>
                <li>QuillJS editor</li>
                <li>Merge fields</li>
                <li>Tables</li>
                <li>Emojis</li>
                <li>Custom fonts</li>

              </ul>
            </span>
          </span>

          <span class="slds-icon_container slds-visual-picker__text-check">
            <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
            </svg>
          </span>
        </label>
      </div>



    <span class=" slds-text-heading_large super-large-number ml2">x {{quantity}} = ${{math.round(quantity * (planType=== 'basic'? 5 : 10))}}</span>
  </div>

</div>
<div *ngIf="paymentProcessed">
<div id="success-container" class="slds-m-left_x-large">
  <div id="success-background" class="slds-m-around_medium">
    <div id="success-header" class="slds-m-around_medium">Success</div>
    <div id="success-text" class="slds-m-around_medium">Your app was installed and a subscription was created which will bill your credit card on a monthly basis until you cancel your account.  </div>
  </div>
</div>
</div>

<div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
  <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>
  <div *ngIf="this.accountError" class="slds-text-heading_small slds-m-top_xxx-small  mr1 ml-auto block ">
    <span class="slds-text-color_error">
      <ng-container>{{this.accountError}}</ng-container>
    </span>
  </div>
  <button *ngIf="!paymentProcessed" (click)="nextButtonClicked()" [attr.disabled]="hasBillingAccount? '': null" aria-label="Waiting for login..." class="block slds-button slds-button_brand">
    <div *ngIf="!isSavingAccountDetails">Next</div>
    <div *ngIf="this.isSavingAccountDetails"
      style="margin-top: .5rem; position: relative; height: 1rem; width: 2.3rem; z-index: 0;">
      <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
    </div>
    <span *ngIf="this.isSavingAccountDetails">Waiting for login...</span>
  </button>
</div>

