import {Injectable} from '@angular/core';
import {PaymentMethod} from '@stripe/stripe-js';
import {BillingAccountCreationInfo} from '../services/payment.service';
import {SubscriptionPlanType} from '../types/plan-type.enum';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InstallStateService {

  constructor(
    private router: Router,
                  ) {
  }

  billingAccount: BillingAccountCreationInfo;
  planType: SubscriptionPlanType;
  installedAppId: string;
  paymentMethod: PaymentMethod;
  quantity = 100;

  private pageState = 0;
  public getPaymentStateLocationAndUpdate(currentPage: number): void{
    if (currentPage  > this.pageState + 1){
        this.router.navigate(['/colorpicker'], );
    }
    this.pageState = currentPage;
  }
  public clearState(): void{
    this.billingAccount = undefined;
    this.planType = undefined;
    this.installedAppId = undefined;
    this.paymentMethod = undefined;
    this.quantity =  100;
    this.pageState = 0;
  }
}
