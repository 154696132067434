<ngl-alert (close)="this.authError = null" *ngIf="authError && !isLoadingAuth" class="slds-theme_alert-texture" iconName="error" variant="error">
  <h2>{{this.authError}}</h2>
</ngl-alert>
<div class="content slds-m-around_x-small">
  <div class="slds-m-top_medium slds-text-body_regular slds-m-left--xx-small block" >Other Premium Settings</div>
  <button class="slds-button slds-button--brand slds-m-top--medium slds-m-left--xx-small block" routerLink="../colorpickerpremiumsetting/fonts">Edit fonts</button>
  <button class="slds-button slds-button--brand slds-m-top--medium block" routerLink="../colorpickerpremiumsetting/merge-fields">Edit merge fields</button>
  <button class="slds-button slds-button--brand slds-m-top--medium block" routerLink="../colorpickerpremiumsetting/pre-defined-headings">Edit pre-defined headings</button>
  <button class="slds-button slds-button_outline-brand slds-m-top--large block" routerLink="../colorpickersettings">Cancel</button>
</div>
