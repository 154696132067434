import {Component, EventEmitter, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-account-input',
  templateUrl: './account-input.component.html',
  styleUrls: ['./account-input.component.css']
})
export class AccountInputComponent implements OnInit {
  @Output()
  formComplete = new EventEmitter();
  @Output()
  formError = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
