import {Component, OnInit} from '@angular/core';
import {ColorSwatch} from "../../../../types/color-swatch";
import {BlockAuthService} from "../../../../services/block-auth.service";
import {BusinessUnitService} from "../../../../services/business-unit.service";

@Component({
  selector: 'app-color-picker-palette-viewer',
  templateUrl: './color-picker-palette-viewer.component.html',
  styleUrls: ['./color-picker-palette-viewer.component.css']
})
export class ColorPickerPaletteViewerComponent implements OnInit {
  isLoadingAuth = true;
  textColors: ColorSwatch[] = [] ;
  private authError: string | any;

  constructor( private blockAuthService: BlockAuthService,private businessUnitService: BusinessUnitService) { }
ngOnInit(): void {


  const sub = this.blockAuthService.getAuthToken().subscribe(() => {


    this.businessUnitService.getColorPalettes().subscribe(returnedTextColors => {
      this.textColors = returnedTextColors[0].color_swatches;
      this.isLoadingAuth = false;

    });
  }, error => {
    this.authError = error.error_description;
    this.isLoadingAuth = false;
  });
  setTimeout(() => {
  this.isLoadingAuth = false;
  sub.unsubscribe();
}, 6000);

}
filterTextColorType(type: string): ColorSwatch[] {
  return this.textColors.filter(x => x.text_types.includes(type));
}


}
