import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-merge-field-settings',
  templateUrl: './merge-field-settings.component.html',
  styleUrls: ['./merge-field-settings.component.css']
})
export class MergeFieldSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
