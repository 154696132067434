<div class="content-block">
  <span class="large-Number ml3 slds-text-color--inverse slds-text-heading_large">2</span>
  <span class="small-des slds-text-color--inverse slds-text-heading_large">Setup your
    account{{continued ? '...continued' : ''}}</span>
</div>
<div class="slds-m-around--xx-large">
  <div *ngIf="!continued">
    <fieldset class="slds-form-element">
      <legend class="slds-form-element__legend slds-form-element__label">Select a plan</legend>
      <div class="slds-form-element__control">

        <div class="slds-visual-picker slds-visual-picker_large " style="width: 49%;" (click)="planType = SubscriptionPlanType.BASIC" >
          <input type="radio" id="visual-picker-101" value="visual-picker-101" name="options" [checked]="planType === SubscriptionPlanType.BASIC"/>
          <label for="visual-picker-101">
            <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
              <span class="slds-m-around_large">

                <span class="slds-text-heading_large super-large-number">$5</span>
                <span class="slds-text-title">per user per month</span>

              </span>
              <span>
                <span class="slds-text-heading_medium">The standard plan includes:</span>
                <ul class="slds-text-align--left" style="list-style: circle; margin-left: 2rem;">
                  <li>$5/user/month</li>
                  <li>10 custom colors</li>
                  <li>Custom text colors</li>
                  <li>Custom background colors</li>
                  <li>QuillJS editor</li>
                  <li>Merge fields</li>
                  <li>Tables</li>
                </ul>
              </span>
            </span>

            <span class="slds-icon_container slds-visual-picker__text-check">
              <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
              </svg>
            </span>
          </label>
        </div>


        <div class="slds-visual-picker slds-visual-picker_large " style="width: 49%;" (click)="planType=SubscriptionPlanType.PREMIUM" >
          <input type="radio" id="visual-picker-102" value="visual-picker-102" name="options" [checked]="planType === SubscriptionPlanType.PREMIUM"/>
          <label for="visual-picker-102">
            <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
              <span class="slds-m-around_large">

                <span class="slds-text-heading_large super-large-number">$10</span>
                <span class="slds-text-title">per user per month</span>

              </span>
              <span>
                <span class="slds-text-heading_medium">The premium plan includes:</span>
                <ul class="slds-text-align--left" style="list-style: circle; margin-left: 2rem;">
                  <li>$10/user/month</li>
                  <li>Unlimited color palettes</li>
                  <li>Unlimited custom colors</li>
                  <li>Custom text colors</li>
                  <li>Custom Background colors</li>
                  <li>QuillJS editor</li>
                  <li>Merge fields</li>
                  <li>Tables</li>
                  <li>Emojis</li>
                  <li>Custom fonts</li>

                </ul>
              </span>
            </span>

            <span class="slds-icon_container slds-visual-picker__text-check">
              <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
              </svg>
            </span>
          </label>
        </div>

      </div>
    </fieldset>
  </div>
</div>
<div *ngIf="continued">
  <div class="slds-m-around--xx-large">
    <div class="slds-grid slds-gutters">
      <div class="slds-col">
        <div class="slds-text-heading_large slds-m-bottom_medium">Billing info</div>
        <form [formGroup]="this.billingInfoForm">

          <div class="slds-grid slds-gutters">


            <div class="slds-col">


              <div [class]="{'slds-has-error': full_name.invalid && (full_name.dirty || full_name.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="full_name">Name on card</label>
                <div class="slds-form-element__control">
                  <input type="text" id="full_name" formControlName="full_name" required placeholder="Marty Hayward"
                    [attr.aria-describedby]="full_name.invalid && (full_name.dirty || full_name.touched)? 'form-error-00': null"
                    [attr.aria-invalid]="full_name.invalid && (full_name.dirty || full_name.touched)? 'true': null"
                         class="slds-input"/>
                </div>
                <div *ngIf="full_name.invalid && (full_name.dirty || full_name.touched)" class="slds-form-element__help"
                                                                                         id="form-error-00">

                  <div *ngIf="full_name.errors.required">
                    Name on card is required.
                  </div>
                  <div *ngIf="full_name.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>


            <div class="slds-col">


              <div [class]="{'slds-has-error': email.invalid && (email.dirty || email.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="email">Email for receipt</label>
                <div class="slds-form-element__control">
                  <input type="text" id="email" formControlName="email" required
                         placeholder="marty.hayward@exzample.com"
                    [attr.aria-describedby]="email.invalid && (email.dirty || email.touched)? 'email-error': null"
                    [attr.aria-invalid]="email.invalid && (email.dirty || email.touched)? 'true': null"
                         class="slds-input"/>  <!-- TODO fix disabled error -->
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="slds-form-element__help"
                                                                             id="email-error">

                  <div *ngIf="email.errors.required">
                    Email is required.
                  </div>
                  <div *ngIf="email.errors.email">
                    Must be a valid email
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="slds-grid slds-gutters">


            <div class="slds-col">


              <div [class]="{'slds-has-error': address.invalid && (address.dirty || address.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="address">Address</label>
                <div class="slds-form-element__control">
                  <input type="text" id="address" formControlName="address" required placeholder="e.g. 101 main St"
                    [attr.aria-describedby]="address.invalid && (address.dirty || address.touched)? 'address-error': null"
                    [attr.aria-invalid]="address.invalid && (address.dirty || address.touched)? 'true': null"
                         class="slds-input"/>
                </div>
                <div *ngIf="address.invalid && (address.dirty || address.touched)" class="slds-form-element__help"
                                                                                   id="address-error">

                  <div *ngIf="address.errors.required">
                    Address is required.
                  </div>
                  <div *ngIf="address.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>


            <div class="slds-col">


              <div [class]="{'slds-has-error': city.invalid && (city.dirty || city.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="city">City</label>
                <div class="slds-form-element__control">
                  <input type="text" id="city" formControlName="city" required placeholder="e.g. New York"
                    [attr.aria-describedby]="city.invalid && (city.dirty || city.touched)? 'city-error': null"
                    [attr.aria-invalid]="city.invalid && (city.dirty || city.touched)? 'true': null"
                         class="slds-input"/>
                </div>
                <div *ngIf="city.invalid && (city.dirty || city.touched)" class="slds-form-element__help"
                                                                          id="city-error">

                  <div *ngIf="city.errors.required">
                    City is required.
                  </div>
                  <div *ngIf="city.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="slds-grid slds-gutters">


            <div class="slds-col slds-size_1-of-2">


              <div [class]="{'slds-has-error': state.invalid && (state.dirty || state.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="state">State</label>
                <div class="slds-form-element__control">

                  <div class="slds-select_container">
                    <select ngl id="state" formControlName="state" required
                      [attr.aria-describedby]="state.invalid && (state.dirty || state.touched)? 'state-error': null"
                      [attr.aria-invalid]="state.invalid && (state.dirty || state.touched)? 'true': null"
                            class="slds-select">
                      <option value="">Select State</option>
                      <option *ngFor="let stateName of this.states" [value]="stateName.abbreviation">{{stateName.name}}</option>

                    </select>
                  </div>
                </div>
                <div *ngIf="state.invalid && (state.dirty || state.touched)" class="slds-form-element__help"
                                                                             id="state-error">

                  <div *ngIf="state.errors.required">
                    State is required.
                  </div>
                  <div *ngIf="state.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>


            <div class="slds-col">


              <div [class]="{'slds-has-error': zip.invalid && (zip.dirty || zip.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="zip">Billing zip code</label>
                <div class="slds-form-element__control">
                  <input type="text" id="zip" formControlName="zip" required placeholder="e.g. 10017"
                    [attr.aria-describedby]="zip.invalid && (zip.dirty || zip.touched)? 'zip-error': null"
                    [attr.aria-invalid]="zip.invalid && (zip.dirty || zip.touched)? 'true': null"
                         class="slds-input"/>
                </div>
                <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="slds-form-element__help"
                                                                       id="zip-error">

                  <div *ngIf="zip.errors.required">
                    Zip is required.
                  </div>
                  <div *ngIf="zip.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="slds-grid slds-gutters">


            <div class="slds-col slds-size_1-of-2">


              <div [class]="{'slds-has-error': country.invalid && (country.dirty || country.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="country">Country</label>
                <div class="slds-form-element__control">
                  <div class="slds-select_container">
                    <select id="country" formControlName="country" required
                      [attr.aria-describedby]="country.invalid && (country.dirty || country.touched)? 'country-error': null"
                      [attr.aria-invalid]="country.invalid && (country.dirty || country.touched)? 'true': null"
                            class="slds-select">
                      <option value="">Select Country</option>
                      <option *ngFor="let countryName of this.countries" [value]="countryName.abbreviation">{{countryName.name}}</option>

                    </select>
                  </div>
                </div>
                <div *ngIf="country.invalid && (country.dirty || country.touched)" class="slds-form-element__help"
                                                                                   id="country-error">

                  <div *ngIf="country.errors.required">
                    Country is required.
                  </div>
                  <div *ngIf="country.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
      </div>
      <div class="slds-col">
        <div class="slds-text-heading_large slds-m-bottom_medium">Credit card details</div>

        <div class="slds-form-element">
          <label class="slds-form-element__label" for="card-input">
            Card Number
          </label>
          <div class="slds-form-element__control">
            <ngx-stripe-card id="card-input" class="slds-input"
              [options]="cardOptions"
              [elementsOptions]="elementsOptions"
              (change)="onChange($event)"
            ></ngx-stripe-card>
          </div>
        </div>
        <div class="slds-text-heading_large slds-m-bottom_medium slds-m-top_medium">Create Account</div>
        <form [formGroup]="this.accountForm">

          <div class="slds-grid slds-gutters">
            <div class="slds-col">


              <div [class]="{'slds-has-error': firstName.invalid && (firstName.dirty || firstName.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="firstname">First Name</label>
                <div class="slds-form-element__control">
                  <input type="text" id="firstname" formControlName="firstName" required placeholder="First Name" [attr.disabled]="this.isSavingAccountDetails?  '':null"
                    [attr.aria-describedby]="firstName.invalid && (firstName.dirty || firstName.touched)? 'form-error-02': null"
                    [attr.aria-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)? 'true': null"
                         class="slds-input"/>  <!-- TODO fix disabled error -->
                </div>
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="slds-form-element__help"
                                                                                         id="form-error-02">

                  <div *ngIf="firstName.errors.required">
                    First Name is required.
                  </div>
                  <div *ngIf="firstName.errors.minlength">
                    Name must be at least 4 characters long.
                  </div>
                  <div *ngIf="firstName.errors.forbiddenName">
                    Name cannot be Bob.
                  </div>
                </div>
              </div>
            </div>
            <div class="slds-col">
              <div [class]="{'slds-has-error': lastName.invalid && (lastName.dirty || lastName.touched)}"
                class="slds-form-element slds-has-error">
                <label class="slds-form-element__label" for="lastName">Last Name</label>
                <div class="slds-form-element__control">
                  <input type="text" id="lastName" formControlName="lastName" required placeholder="Last name" [attr.disabled]="this.isSavingAccountDetails? '':null"
                    [attr.aria-describedby]="lastName.invalid && (lastName.dirty || lastName.touched)? 'form-error-01': null"
                    [attr.aria-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)? 'true': null"
                         class="slds-input"/>
                </div> <!-- TODO fix disabled error -->
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="slds-form-element__help"
                                                                                      id="form-error-01">

                  <div *ngIf="lastName.errors.required">
                    Last Name is required.
                  </div>
                  <div *ngIf="lastName.errors.minlength">
                    Last Name must be at least 4 characters long.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [class]="{'slds-has-error': emailAccount.invalid && (emailAccount.dirty || emailAccount.touched)}"
            class="slds-form-element slds-has-error">
            <label class="slds-form-element__label" for="email-account">Account Email</label>
            <div class="slds-form-element__control">
              <input type="text" id="email-account" formControlName="email" required placeholder="info@10e15.com" [attr.disabled]="this.isSavingAccountDetails ? '':null"
                [attr.aria-describedby]="emailAccount.invalid && (emailAccount.dirty || emailAccount.touched)? 'form-error-03': null"
                [attr.aria-invalid]="emailAccount.invalid && (emailAccount.dirty || emailAccount.touched)? 'true': null"
                     class="slds-input"/> <!-- TODO fix disabled error -->
            </div>
            <div *ngIf="emailAccount.invalid && (emailAccount.dirty || emailAccount.touched)" class="slds-form-element__help"
                                                                                              id="form-error-03">

              <div *ngIf="emailAccount.errors.required">
                Account email is required.
              </div>
              <div *ngIf="emailAccount.errors.emailAccount">
                Must be a valid Account email
              </div>

            </div>
          </div>

        </form>


      </div>
    </div>

    <div class="slds-form-element slds-m-top_medium">
      <label class="slds-form-element__label slds-m-right_none" for="numberSeats">
        <div class="slds-text-title ">Users (all contentent editors in your BU need a license,<a
          href="javascript:void(0);">see terms</a>):
        </div>
      </label>
      <div class="slds-form-element__control">
        <button
          class="slds-button slds-button_icon slds-button_icon-small slds-input__button_decrement" (click)="updateQuantity(-1)"
          title="Decrement counter">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#ban"></use>
          </svg>
          <span class="slds-assistive-text">Decrement counter</span>
        </button>
        <input type="number" id="numberSeats" placeholder="100"
               class="slds-input slds-input_counter"  [(ngModel)]="this.quantity" value="10"/>
        <button
          class="slds-button slds-button_icon slds-button_icon-small slds-input__button_increment"  (click)="updateQuantity(1)"
          title="Increment counter">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new"></use>
          </svg>
          <span class="slds-assistive-text">Increment counter</span>
        </button>
      </div>
    </div>

    <div class="slds-color__text_gray-8 slds-m-top_medium">
      Your business unit has {{quantity}} users. Each user with content editor permissions needs a license. Any user
      with admin permissions in your BU can cancel at any time via the settings tab of the app, or by contacting
      info@e8enterprizes.com.
      <br/>

      By purchasing this app you agree to let E8 Enterprizes, LLC store your name, email address, API access tokens for
      your users, and Business Unit ID. You also agree to let Stripe process your credit card information.
    </div>
  </div>

</div>

<div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
  <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>

  <div *ngIf="this.accountError" class="slds-text-heading_small slds-m-top_xxx-small  mr2 ml-auto block ">
    <span class="slds-text-color_error">
      <ng-container>{{this.accountError}}</ng-container>
    </span>
  </div>
  <button (click)="nextButtonClicked()"  [disabled]="((accountForm.invalid||billingInfoForm.invalid) && continued )  || !planType || (continued && isStripeCardInfoInvalid)" [attr.title]="(billingInfoForm.valid && accountForm.valid ) || (!continued)? 'Next Step' : 'Must input valid fields' "
    class="slds-button slds-button_brand">Next
  </button>
</div>

