import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthConfig} from '../types/auth.config';

@Injectable({
  providedIn: 'root'
})
export class InstalledAppService {

  constructor(private http: HttpClient) {
  }

  public static installedAppId: string;

  public getConfigAuthInfo(): Observable<AuthConfig> {
    return this.http.get<AuthConfig>(`${environment.baseOrigin}/api/AI_config/callback_configs/${InstalledAppService.installedAppId}`);

  }

  public createNewInstalledApp(installedAppConfig: InstalledAppConfig): Observable<string> {
    return this.http.post<string>(`${environment.baseOrigin}/api/AI_config/createApp`, installedAppConfig);

  }
}
interface InstalledAppConfig {
  installed_id: string;
  client_id: string;
  client_secret: string;
  subdomain: string;
}
