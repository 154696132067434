<div class="content-block">
  <span class="large-Number ml3 slds-text-color--inverse slds-text-heading_large">4</span>
  <span class="small-des slds-text-color--inverse slds-text-heading_large">Submit the API info</span>
</div>
<div class="slds-m-around--xx-large ">
  <div class="slds-text-heading_large slds-m-bottom_medium">Please submit the form below with the API info you retrieved
    from your installed package (all fields are required):
  </div>

  <form [formGroup]="this.apiInfoForm">

    <div class="slds-grid slds-gutters">


      <div class="slds-col">


        <div [class]="{'slds-has-error': clientId.invalid && (clientId.dirty || clientId.touched)}"
          class="slds-form-element slds-has-error">
          <label class="slds-form-element__label" for="clientId">Client ID</label>
          <div class="slds-form-element__control">
            <input type="text" id="clientId" formControlName="clientId" required
                   placeholder="(24 alphanumeric characters)"
              [attr.aria-describedby]="clientId.invalid && (clientId.dirty || clientId.touched)? 'form-error-00': null"
              [attr.aria-invalid]="clientId.invalid && (clientId.dirty || clientId.touched)? 'true': null"
                   class="slds-input"/>
          </div>
          <div *ngIf="clientId.invalid && (clientId.dirty || clientId.touched)" class="slds-form-element__help"
                                                                                id="form-error-00">

            <div *ngIf="clientId.errors.required">
              Client Id is required.
            </div>
            <div *ngIf="clientId.errors.minlength ||  clientId.errors.maxlength">
              Client Id must be exactly 24 characters long.
            </div>
            <div *ngIf="clientId.errors.pattern">
              Must be alphanumeric characters
            </div>
          </div>
        </div>
      </div>


      <div class="slds-col">


        <div [class]="{'slds-has-error': clientSecret.invalid && (clientSecret.dirty || clientSecret.touched)}"
          class="slds-form-element slds-has-error">
          <label class="slds-form-element__label" for="clientSecret">Client Secret</label>
          <div class="slds-form-element__control">
            <input type="text" id="clientSecret" formControlName="clientSecret" required
                   placeholder="(24 alphanumeric characters)"
              [attr.aria-describedby]="clientSecret.invalid && (clientSecret.dirty || clientSecret.touched)? 'clientSecret-error': null"
              [attr.aria-invalid]="clientSecret.invalid && (clientSecret.dirty || clientSecret.touched)? 'true': null"
                   class="slds-input"/>  <!-- TODO fix disabled error -->
          </div>
          <div *ngIf="clientSecret.invalid && (clientSecret.dirty || clientSecret.touched)"
            class="slds-form-element__help"
            id="clientSecret-error">

            <div *ngIf="clientSecret.errors.required">
              Client Secret is required.
            </div>
            <div *ngIf="clientSecret.errors.minLength ||  clientSecret.errors.maxlength">
              Client Secret must be exactly 24 characters long.
            </div>
            <div *ngIf="clientSecret.errors.pattern">
              Must be alphanumeric characters
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="slds-grid slds-gutters">


      <div class="slds-col slds-size_1-of-2">


        <div [class]="{'slds-has-error': authBaseUrl.invalid && (authBaseUrl.dirty || authBaseUrl.touched)}"
          class="slds-form-element slds-has-error">
          <label class="slds-form-element__label" for="authBaseUrl">Authentication Base URI</label>
          <div class="slds-form-element__control">
            <input type="text" id="authBaseUrl" formControlName="authBaseUrl" required
                   placeholder="(URL)" (focusout)="populateEverything()"
              [attr.aria-describedby]="authBaseUrl.invalid && (authBaseUrl.dirty || authBaseUrl.touched)? 'authBaseUrl-error': null"
              [attr.aria-invalid]="authBaseUrl.invalid && (authBaseUrl.dirty || authBaseUrl.touched)? 'true': null"
                   class="slds-input"/>
          </div>
          <div *ngIf="authBaseUrl.invalid && (authBaseUrl.dirty || authBaseUrl.touched)" class="slds-form-element__help"
                                                                                         id="authBaseUrl-error">

            <div *ngIf="authBaseUrl.errors.required">
              Auth Base URl is required.
            </div>
            <div *ngIf="authBaseUrl.errors.pattern">
              Must be valid URL
            </div>
          </div>
        </div>
      </div>


      <div class="slds-col slds-size_1-of-2">


      </div>

    </div>
    <div class="slds-grid slds-gutters">

      <div class="slds-col slds-size_1-of-2">
        <div [class]="{'slds-has-error': restBaseUrl.invalid && (restBaseUrl.dirty || restBaseUrl.touched)}"
          class="slds-form-element slds-has-error">
          <label class="slds-form-element__label" for="restBaseUrl">REST Base URI</label>
          <div class="slds-form-element__control">
            <input type="text" id="restBaseUrl" formControlName="restBaseUrl" required
                   placeholder="(URL)" (focusout)="populateEverything()"
              [attr.aria-describedby]="restBaseUrl.invalid && (restBaseUrl.dirty || restBaseUrl.touched)? 'restBaseUrl-error': null"
              [attr.aria-invalid]="restBaseUrl.invalid && (restBaseUrl.dirty || restBaseUrl.touched)? 'true': null"
                   class="slds-input"/>
          </div>
          <div *ngIf="restBaseUrl.invalid && (restBaseUrl.dirty || restBaseUrl.touched)" class="slds-form-element__help"
                                                                                         id="restBaseUrl-error">

            <div *ngIf="restBaseUrl.errors.required">
              REST Base URl is required.
            </div>
            <div *ngIf="restBaseUrl.errors.pattern">
              Must be valid URL
            </div>
          </div>
        </div>


      </div>

      <div class="slds-col slds-size_1-of-2">


        <div [class]="{'slds-has-error': soapBaseUrl.invalid && (soapBaseUrl.dirty || soapBaseUrl.touched)}"
          class="slds-form-element slds-has-error">
          <label class="slds-form-element__label" for="soapBaseUrl">SOAP Base URI</label>
          <div class="slds-form-element__control">
            <input type="text" id="soapBaseUrl" formControlName="soapBaseUrl" required placeholder="(URL)"
              [attr.aria-describedby]="soapBaseUrl.invalid && (soapBaseUrl.dirty || soapBaseUrl.touched)? 'soapBaseUrl-error': null"
              [attr.aria-invalid]="soapBaseUrl.invalid && (soapBaseUrl.dirty || soapBaseUrl.touched)? 'true': null"
                   class="slds-input"/>
          </div>
          <div *ngIf="soapBaseUrl.invalid && (soapBaseUrl.dirty || soapBaseUrl.touched)" class="slds-form-element__help"
                                                                                         id="soapBaseUrl-error">

            <div *ngIf="soapBaseUrl.errors.required">
              SOAP Base URl is required.
            </div>
            <div *ngIf="soapBaseUrl.errors.pattern">
              Must be valid URL
            </div>
          </div>
        </div>
      </div>


    </div>


  </form>


</div>
<div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
  <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>
  <div *ngIf="this.accountError" class="slds-text-heading_small slds-m-top_xxx-small  mr1 ml-auto block ">
    <span class="slds-text-color_error">
      <ng-container>{{this.accountError}}</ng-container>
    </span>
  </div>
  <button (click)="nextButtonClicked()"
    class="block slds-button slds-button_brand" [disabled]="this.apiInfoForm.invalid">
    <div *ngIf="!isSavingAccountDetails">Next</div>
    <div *ngIf="this.isSavingAccountDetails" style="position:relative; height:1.87rem; width: 2.3rem; z-index:0;">
      <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
    </div>
    <span *ngIf="this.isSavingAccountDetails">Waiting for login...</span>
  </button>
</div>


