<div *ngIf="isLoadingAuth" class="demo-only" style="height:6rem">
  <div class="slds-spinner_container">
    <div role="status" class="slds-spinner slds-spinner_large slds-spinner_brand">
      <span class="slds-assistive-text">Loading</span>
      <div class="slds-spinner__dot-a"></div>
      <div class="slds-spinner__dot-b"></div>
    </div>
  </div>
</div>
<ngl-alert *ngIf="authError && !isLoadingAuth" class="slds-theme_alert-texture" variant="error" iconName="error" (close)="this.authError = null">
  <h2>{{this.authError}}</h2>
</ngl-alert>
<div *ngIf="!isLoadingAuth">
  <ngl-alert *ngIf="successParam" class="slds-theme_alert-texture" variant="success" iconName="success" (close)="this.successParam = null">
    <h2>{{this.successParam}}</h2>
  </ngl-alert>

  <ngl-alert *ngIf="errorParam" class="slds-theme_alert-texture" variant="error" iconName="error" (close)="this.errorParam = null">
    <h2>{{this.errorParam}}</h2>
  </ngl-alert>

</div>
<div *ngIf="!isLoadingAuth" class="content slds-m-around_x-small">
<div class="slds-text-body_regular ">{{isAdmin ? "The colors you set here will be visible to all users in your Business Unit that have the ColorPicker app installed." : "The following colors have been set by your admin. Please contact your admin to change colors."}}</div>
<app-color-picker-palette-viewer *ngIf="currentPlanTypeActive === SubscriptionPlanType.BASIC" class="slds-m-top_medium"></app-color-picker-palette-viewer>
<app-color-picker-premium-viewer *ngIf="currentPlanTypeActive === SubscriptionPlanType.PREMIUM" class="slds-m-top_medium"></app-color-picker-premium-viewer>
  <button *ngIf="isAdmin && (currentPlanTypeActive === SubscriptionPlanType.BASIC)" class="slds-button slds-button_brand slds-m-top_medium " routerLink="../colorpalette" >Edit Custom Colors</button>
  <button *ngIf="isAdmin && (currentPlanTypeActive === SubscriptionPlanType.PREMIUM)" class="slds-button slds-button_brand slds-m-top_medium " routerLink="../colorpalette" [queryParams]="{cp_id: uuid}" >Add a color palette</button>
  <div *ngIf="!isAdmin && (currentPlanTypeActive !== SubscriptionPlanType.PREMIUM)" class="slds-m-top_medium slds-text-body_regular">If you need more colors or would like to have multiple swatches/palettes to chose from, please <a href="javascript:void(0);">ask your admin to upgrade your account.</a> </div>
  <div *ngIf="isAdmin && (currentPlanTypeActive !== SubscriptionPlanType.PREMIUM)" class="slds-m-top_medium slds-text-body_regular">If you need more colors or would like to have multiple swatches/palettes to chose from, please <a routerLink="../colorpayment" >upgrade your account.</a> </div>
  <div class="account-options slds-m-top_medium">
    <div *ngIf="currentPlanTypeActive === SubscriptionPlanType.PREMIUM" class="slds-m-top_small slds-text-body_regular"><a routerLink="../colorpickerpremiumsetting">Manage other premium settings</a></div>
    <div class="slds-m-top_small slds-text-body_regular"><a routerLink="../colorpayment">Modify your billing info.</a></div>
    <div class="slds-m-top_small slds-text-body_regular"><a routerLink="../colorpayment/cancel">Cancel your account.</a></div>
    <div class="slds-m-top_small slds-text-body_regular"><a href="mailto:info@e8enterprizes.com">Contact customer support.</a></div>
  </div>
</div>
