import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthToken} from '../types/sales-force/auth-token';
import {environment} from '../../environments/environment';
import {Observable, Subscriber} from 'rxjs';
import {BlockSdkService} from './block-sdk.service';
import {InstalledAppService} from './installed-app.service';
import {AuthConfig} from '../types/auth.config';

@Injectable({
  providedIn: 'root'
})
export class BlockAuthService {
  private tokenKey = 'sf_access_token';
  constructor(private http: HttpClient, private blockSdkService: BlockSdkService, private installedAppService: InstalledAppService) {
  }

  private token: AuthToken;

  private latestAuthTokenRefreshDate: Date;
  public authError: string;

  private beginOauthFlow(authConfig?: AuthConfig): Observable<AuthToken>{

    return new Observable<AuthToken>((observer) => {
      if (this.token === undefined) {
        if (window.addEventListener) {
          window.addEventListener('message', this.handleAuthResponse.bind(this, observer), false);
        } else {
          (window as any).attachEvent('onmessage', this.handleAuthResponse.bind(this, observer));
        }
        if (!authConfig) {
          this.installedAppService.getConfigAuthInfo()
            .subscribe(authConfigLoaded => {
              const authURL = `https://${authConfigLoaded.subdomain}.auth.marketingcloudapis.com/`;
              const clientId = authConfigLoaded.client_id;
              const redirectURL = authConfigLoaded.redirect_url;
              this.blockSdkService.getBlockSDK().triggerAuth2({authURL, clientId, redirectURL});

            }, error => {
              observer.error({error_description: `Unable to get app config. Please check install config!`});
            });
        } else {
          // We just need the listener
          // const authURL = `https://${authConfig.subdomain}.auth.marketingcloudapis.com/`;
          // const clientId = authConfig.client_id;
          // const redirectURL = authConfig.redirect_url;
         // this.blockSdkService.getBlockSDK().triggerAuth2({authURL, clientId, redirectURL});
        }
      }else {
        observer.next(this.token);
      }
    });

  }

  public getAuthToken(authConfig?: AuthConfig): Observable<AuthToken>{
    if (this.isAuthenticated()){
      return new Observable<AuthToken>(obj => obj.next(this.token));
    }else {
      const storedToken = this.getStoredToken();
      // if (!storedToken){
      return this.beginOauthFlow(authConfig);
      // }
      // else {
      //   const timeCreatedToken = new Date(storedToken.ttl);
      //   const currentTime = new Date();
      //   const diff = currentTime.getTime() - timeCreatedToken.getTime();
      //
      //   const secondsBetweenDates = Math.abs(diff / 1000);
      //   if ( storedToken.token.expires_in < secondsBetweenDates ){
      //     return this.beginOauthFlow(); // Maybe use a refresh token instead
      //   }else {
      //     this.latestAuthTokenRefreshDate = new Date(storedToken.ttl);
      //     this.token = storedToken.token;
      //     return new Observable<AuthToken>(obj => obj.next(this.token));
      //   }
      // }

    }

  }
  private getStoredToken(): any{
    return JSON.parse(sessionStorage.getItem(this.tokenKey));
  }

  private removeStoredToken(): void{
     sessionStorage.removeItem(this.tokenKey);
  }
  private storeToken(tokenObj): void{
    sessionStorage.setItem(this.tokenKey, JSON.stringify(tokenObj));
  }
  public isAuthenticated(): boolean {
    if ( this.token != null ){
      const secondsBetweenDates = this.getAuthTokenLastRefreshSeconds();
      if (this.token.expires_in > secondsBetweenDates){
        return true;
      }
    }
    return false;
  }

  public injectAuth(data?: any): { data?: any; auth: { accesstoken: string } }{
    return {data: data, auth: {accesstoken: this.getAccessToken()}};
  }
  // Not safe
  public getUserData(): Observable<any>{
    if (this.isAuthenticated()){
      return this.http.post(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/blocksdk/user-info`,
        this.injectAuth());
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  private getAuthTokenLastRefreshSeconds(): number {
    const currentTime = new Date();
    const dif = currentTime.getTime() - this.latestAuthTokenRefreshDate.getTime();
    return Math.abs(dif / 1000);
  }

  private handleAuthResponse(authSubscriber: Subscriber<AuthToken>, messageEvent: MessageEvent): void {
    if ( messageEvent.origin === environment.baseOrigin ) {
      // Retrieve data sent in postMessage
      if (messageEvent.data.message !== undefined ) {
        if (messageEvent.data.message === 'token') {
          console.log('Received Auth Result ');
          const authResult = messageEvent.data.token;
          this.token = JSON.parse(authResult);
          this.latestAuthTokenRefreshDate = new Date();
          const currentTime: number = this.latestAuthTokenRefreshDate.getTime();
          this.storeToken({ttl: currentTime, token: this.token});
          authSubscriber.next(this.token);
        }else if (messageEvent.data.message === 'error'){
          const authResult = messageEvent.data.error;
          const parsedError = JSON.parse(authResult);
          parsedError.error_description = unescape(parsedError.error_description);
          console.error(`Received Auth Result Error: ${parsedError.error_description}`);
          this.authError = parsedError.error_description;
          authSubscriber.error(parsedError);
        }
      }
    }
  }
  public getAccessToken(): string{
    return this.token.access_token;
  }
}
