import {Component, OnInit} from '@angular/core';
import {StripeService} from 'ngx-stripe';
import {CompletionState, PaymentPage, PaymentPageState, PaymentState} from './payment.state';
import {BillingDetails} from './billing-details/billing-details';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';

@Component({
  selector: 'app-color-picker-payment',
  templateUrl: './color-picker-payment.component.html',
  styleUrls: ['./color-picker-payment.component.css']
})
export class ColorPickerPaymentComponent implements OnInit {

  billingDetails: BillingDetails;
  paymentState: PaymentState;
  PaymentPage = PaymentPage;
  noLicenceAlert: boolean;
  constructor(private stripe: StripeService,
              private router: Router,
              private activatedRoute: ActivatedRoute ,
              private billingAccountService: PaymentService) {

    const paymentPageStates: PaymentPageState = {
      [PaymentPage.CREATE]: CompletionState.INCOMPLETE,
      [PaymentPage.BILLING]: CompletionState.INCOMPLETE,
      [PaymentPage.PAY]: CompletionState.INCOMPLETE,
    };
    this.paymentState = {
      activePaymentPage: PaymentPage.CREATE,
      paymentPageStates
    };
  }

  ngOnInit(): void {
    this.billingAccountService.getBillingAccountState().subscribe(accountState => {
      if (!accountState.billingAccountState.accountExists || accountState.billingAccountState.paymentStatus === `free`){
        this.noLicenceAlert = true;
      }
    });
  }

  setPageComplete(paymentPage: PaymentPage): void{
    this.paymentState.paymentPageStates[paymentPage] = CompletionState.COMPLETE;
  }

  setPageCurrent(paymentPage: PaymentPage): void{
    this.paymentState.paymentPageStates[paymentPage] = CompletionState.CURRENT;
  }

  setPageError(paymentPage: PaymentPage): void{
    this.paymentState.paymentPageStates[paymentPage] = CompletionState.ERROR;
  }

  setPageActive(paymentPage: PaymentPage): void{
    this.paymentState.activePaymentPage = paymentPage;
  }

  getPageActive(paymentPage: PaymentPage): boolean {
    return this.paymentState.activePaymentPage === paymentPage;
  }

  getPageCurrent(paymentPage: PaymentPage): boolean{
    return this.paymentState.paymentPageStates[paymentPage] === CompletionState.CURRENT;
  }

  getPageCompleted(paymentPage: PaymentPage): boolean{
    return this.paymentState.paymentPageStates[paymentPage]  === CompletionState.COMPLETE;
  }

  createFormCompleted(event: any): void {
    console.log('formCompleted');
    this.setPageComplete(PaymentPage.CREATE);
    this.setPageActive(PaymentPage.BILLING);
  }
  billingFormCompleted(billingDetails: BillingDetails): void {
    console.log('formCompleted', JSON.stringify(billingDetails));
    this.billingDetails = billingDetails;
    this.setPageComplete(PaymentPage.BILLING);
    this.setPageActive(PaymentPage.PAY);
  }

  navFromPath(paymentPage: PaymentPage): void {
      if (!this.getPageActive(paymentPage)){
        if (this.paymentState.paymentPageStates[paymentPage] === CompletionState.COMPLETE){
          this.setPageActive(paymentPage);
        }
      }
  }

  paymentFormCompleted($event: any): void{
    this.setPageComplete(PaymentPage.PAY);
    this.router.navigate(['../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { success: 'Your payment has been Successful' }});

  }

  paymentFormError(error: string): void {

  }
}

