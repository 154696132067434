import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InstallStateService} from '../install-state.service';

@Component({
  selector: 'app-color-picker-setup-p1',
  templateUrl: './color-picker-setup-p1.component.html',
  styleUrls: ['./color-picker-setup-p1.component.css']
})
export class ColorPickerSetupP1Component implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private installService: InstallStateService,
  ) {
  }

  continued = false;

  ngOnInit(): void {
    this.installService.getPaymentStateLocationAndUpdate(1);
  }

  nextButtonClicked(): void {
    if (this.continued === false) {
      this.continued = true;
    } else {
      this.router.navigate(['../2'], {relativeTo: this.activatedRoute});
    }
  }
}
