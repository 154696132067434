import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BlockAuthService} from './block-auth.service';
import {InstalledAppService} from './installed-app.service';
import {PaymentMethod} from '@stripe/stripe-js';
import {StripeService} from 'ngx-stripe';
import {SubscriptionPlanType} from '../types/plan-type.enum';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private blockAuthService: BlockAuthService,
              private stripeService: StripeService) { }

  public createSetBillingAccount(accountInfo: BillingAccountCreationInfo): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/create-account`,
        this.blockAuthService.injectAuth(accountInfo));
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public updateBillingAccount(accountInfo: BillingAccountCreationInfo): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/billing-account/update`,
        this.blockAuthService.injectAuth(accountInfo));
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public cancelSubscription(): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/subscription/cancel`,
        this.blockAuthService.injectAuth());
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public subscribeToPlan(subscriptionPlanInfo: SubscriptionPlanInfo, planType: SubscriptionPlanType ): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/subscribe/${planType}`,
        this.blockAuthService.injectAuth(subscriptionPlanInfo));
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }


  public getBillingAccountInfo(): Observable<BillingAccountCreationInfo> {
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<BillingAccountCreationInfo>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/billing-account`,
        this.blockAuthService.injectAuth()
         );
    } else {
      return new Observable<BillingAccountCreationInfo>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public getBillingAccountState(): Observable<any> {
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/payment/billing-account/status`,
        this.blockAuthService.injectAuth());
    } else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public usePaymentMethodToSubscribe(paymentMethod: PaymentMethod, quantity: number, planType: SubscriptionPlanType): Observable<any>{
    return new Observable<any>(subscriber => {
      this.subscribeToPlan({paymentMethodId: paymentMethod.id, quantity: quantity}, planType)
        .toPromise().then(async subscription => {
        return  {
          paymentMethodId: paymentMethod.id,
          priceId: subscription.items.data[0].price.id,
          subscription: subscription,
        };
      }, error => {
        subscriber.error(error);
        console.log(`Error Subscribing ${error}`);
      })
        .then(this.handlePaymentThatRequiresCustomerAction.bind(this, subscriber))
        .then(this.handleRequiresPaymentMethod.bind(this))
        .then(this.onSubscriptionComplete.bind(this, subscriber))
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          subscriber.error(error);
        });
    });

  }

  private onSubscriptionComplete(subscriber: Subscriber<any>, result: any): void{
    // Payment was successful.
    if (result.subscription.status === 'active') {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
     subscriber.next(result);
    }

  }
  private handlePaymentThatRequiresCustomerAction(subscriber: Subscriber<any>, {
                                            subscription,
                                            invoice,
                                            priceId,
                                            paymentMethodId,
                                            isRetry,
                                          }): any {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return this.stripeService
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        }).toPromise().then(
          (result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              throw result;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                // There's a risk of the customer closing the window before the callback.
                // We recommend setting up webhook endpoints later in this guide.
                subscriber.next({
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                });
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          });

    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  private handleRequiresPaymentMethod({
                                subscription,
                                paymentMethodId,
                                priceId,
                              }): any {
    if (subscription.status === 'active') { // TODO fill out this retry logic
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using sessonStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      sessionStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      sessionStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }
}

export interface SubscriptionPlanInfo {
  paymentMethodId: string;
  quantity: number;
}
export interface BillingAccountCreationInfo {
  firstName: string;
  lastName: string;
  email: string;
}
