import {Component, OnInit} from '@angular/core';
import {BlockAuthService} from '../../../../services/block-auth.service';
import {BusinessUnitService} from '../../../../services/business-unit.service';
import {ColorPalette} from '../../../../types/color-palette';
import {ActivatedRoute, Router} from '@angular/router';
import {ColorSwatch} from '../../../../types/color-swatch';

@Component({
  selector: 'app-color-picker-premium-viewer',
  templateUrl: './color-picker-premium-viewer.component.html',
  styleUrls: ['./color-picker-premium-viewer.component.css']
})
// TODO update name to make sense
export class ColorPickerPremiumViewerComponent implements OnInit {

  isLoadingAuth = true;
  textColors: ColorPalette[] = [] ;
  private authError: string | any;

  constructor( private blockAuthService: BlockAuthService,
               private businessUnitService: BusinessUnitService) { }

  ngOnInit(): void {

    const sub = this.blockAuthService.getAuthToken().subscribe(() => {

      this.businessUnitService.getColorPalettes().subscribe(returnedTextColors => {
        this.textColors = returnedTextColors;
        this.isLoadingAuth = false;

      });
    }, error => {
      this.authError = error.error_description;
      this.isLoadingAuth = false;
    });
    setTimeout(() => {
      this.isLoadingAuth = false;
      sub.unsubscribe();
    }, 6000);

  }

  filterTextColorType(colorPalette: ColorPalette, textColor: string): ColorSwatch[]{
    return colorPalette.color_swatches.filter(x => x.text_types.includes(textColor));
  }

}
