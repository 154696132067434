import {Injectable} from '@angular/core';
import * as BlockSDK from 'blocksdk';
import {InstalledAppService} from './installed-app.service';

@Injectable({
  providedIn: 'root'
})
export class BlockSdkService {
  private sdk: BlockSDK;

  constructor() { }

  public getBlockSDK(): BlockSDK {
    if (this.sdk) {
    return this.sdk;
    }
    else {
      return this.createNewBlockSDK();
    }
  }
  public createNewBlockSDK(): BlockSDK {
    this.sdk = new BlockSDK({
      tabs: [
        {
          key: 'settings',
          url: `${window.location.origin}/IA/${InstalledAppService.installedAppId}/colorpickersettings/`,
          name: 'Settings'
        }, // This is a custom tab
        'htmlblock', // This is the HTML Editor Tab
        'stylingblock' // This is the styling tab
      ]
    }, ['blocktester.herokuapp.com', 'localhost', 'marketingcloudapps.com'], true);
    return this.sdk;
  }
}
