<ngl-alert *ngIf="this.noLicenceAlert" class="slds-theme_alert-texture" variant="info" iconName="info" >
  <h2>Your Business Unit has ColorPicker configured but no more licenses are available. Purchase one now.</h2>
</ngl-alert>
<div class="slds-m-around_medium">
<div class="slds-path">
  <div class="slds-grid slds-path__track">
    <div class="slds-grid slds-path__scroller-container">
      <div class="slds-path__scroller" role="application">
        <div class="slds-path__scroller_inner">
          <ul class="slds-path__nav" role="listbox" aria-orientation="horizontal">
            <li [class]="{'slds-is-current': this.getPageCurrent(PaymentPage.CREATE), 'slds-is-incomplete': !this.getPageCurrent(PaymentPage.CREATE),'slds-is-active':this.getPageActive(PaymentPage.CREATE), 'slds-is-complete': this.getPageCompleted((PaymentPage.CREATE))}" class="slds-path__item slds-is-active" role="presentation">
            <a aria-selected="true" class="slds-path__link" (click)="navFromPath(PaymentPage.CREATE)" id="path-1" role="option" tabindex="0">
              <span class="slds-path__stage">
                <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                </svg>
                  <span class="slds-assistive-text">Current Stage:</span>
                </span>
                <span class="slds-path__title">Create Account</span>
              </a>
            </li>
            <li [class]="{'slds-is-current': this.getPageCurrent(PaymentPage.BILLING), 'slds-is-incomplete': !this.getPageCurrent(PaymentPage.BILLING),'slds-is-active':this.getPageActive(PaymentPage.BILLING), 'slds-is-complete': this.getPageCompleted((PaymentPage.BILLING))}"  class="slds-path__item" role="presentation">
              <a aria-selected="false" class="slds-path__link" (click)="navFromPath(PaymentPage.BILLING)" id="path-4" role="option" tabindex="-1">
                <span class="slds-path__stage">
                  <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                  </svg>
                </span>
                <span class="slds-path__title">Billing Details</span>
              </a>
            </li>
            <li [class]="{'slds-is-current': this.getPageCurrent(PaymentPage.PAY), 'slds-is-incomplete': !this.getPageCurrent(PaymentPage.PAY),'slds-is-active':this.getPageActive(PaymentPage.PAY), 'slds-is-complete': this.getPageCompleted((PaymentPage.PAY))}"  class="slds-path__item" role="presentation">
              <a aria-selected="false" class="slds-path__link" (click)="navFromPath(PaymentPage.PAY)" id="path-5" role="option" tabindex="-1">
                <span class="slds-path__stage">
                  <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                  </svg>
                </span>
                <span class="slds-path__title">Pay</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-account-creation *ngIf="paymentState.activePaymentPage == PaymentPage.CREATE" (formComplete)="createFormCompleted($event)"></app-account-creation>
<app-billing-details *ngIf="paymentState.activePaymentPage == PaymentPage.BILLING" (formComplete)="billingFormCompleted($event)"></app-billing-details>
<app-account-payment *ngIf="paymentState.activePaymentPage == PaymentPage.PAY" [billingDetails]="this.billingDetails" (formComplete)="paymentFormCompleted($event)" (formError)="paymentFormError($event)"></app-account-payment>
</div>
