import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AccountInputComponent} from '../account-input/account-input.component';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {BillingDetails} from '../billing-details/billing-details';
import {PaymentService} from '../../../../services/payment.service';
import {SubscriptionPlanType} from '../../../../types/plan-type.enum';

@Component({
  selector: 'app-account-payment',
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.css']
})
export class AccountPaymentComponent  extends AccountInputComponent implements OnInit {

  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;

  @Input()
  billingDetails: BillingDetails;
  quantity = 10;
  isSavingAccountDetails: boolean;
  accountError: string;

  planType: SubscriptionPlanType;
  SubscriptionPlanType = SubscriptionPlanType;

  constructor(private stripeService: StripeService, private paymentService: PaymentService) {
    super();
  }
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#706E6B',
        color: '#706E6B',
        fontWeight: '300',
        fontFamily: '"Salesforce Sans",Arial,sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  ngOnInit(): void {
  }

  createToken(): void {
    this.isSavingAccountDetails = true;
    this.stripeService
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
        billing_details: {
          address: {
            city: this.billingDetails.city,
            country: this.billingDetails.country,
            line1: this.billingDetails.address_ln_1,
            postal_code: this.billingDetails.zip,
            state: this.billingDetails.state,
          },
          email: this.billingDetails.email,
          name: this.billingDetails.full_name,
        },
      }).subscribe(pmResult => {
        if (pmResult.paymentMethod){
          //  Payment Method Retrieved
        this.paymentService.usePaymentMethodToSubscribe(pmResult.paymentMethod, this.quantity, this.planType)
          .subscribe(value => {
            this.isSavingAccountDetails = false;
            this.formComplete.emit(`${value.subscription.items.data[0].price.product}`);
          }, error => this.displayUserError(error));

        } else if (pmResult.error){
          this.isSavingAccountDetails = false;
          this.accountError = pmResult.error.message;
          // Error creating token
          this.formError.emit(pmResult.error);
          console.log(`Error: ${pmResult.error.message}`);
        }
    }, error => {
      this.isSavingAccountDetails = false;
      this.accountError = error.error.message;
      this.formError.emit(error.error.error);
    });

  }

  private displayUserError(error): void {
    if (error && error.error) {
      if (error.error.message) {
        this.accountError = error.error.message;
        this.formError.emit(error.error.message);
        console.log(`Error: ${error.error.message}`);
      }else {
        this.accountError = error.error.error;
        this.formError.emit(error.error.error);
        console.log(`Error: ${error.error.error}`);
      }
    }else {
      this.accountError = error;
      this.formError.emit(error);
      console.log(`Error: ${error}`);
    }
    this.isSavingAccountDetails = false;
    }

  updateQuantity(changeBy: number): void{
    this.quantity += changeBy;
  }

  getPrice(): number {
    return this.planType === SubscriptionPlanType.BASIC ? 5 : 10;
  }

}
