import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pre-defined-heading-settings',
  templateUrl: './pre-defined-heading-settings.component.html',
  styleUrls: ['./pre-defined-heading-settings.component.css']
})
export class PreDefinedHeadingSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
