import {Component, OnInit} from '@angular/core';
import {SubscriptionPlanType} from '../../../types/plan-type.enum';
import {PaymentService} from '../../../services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-color-picker-payment-cancel',
  templateUrl: './color-picker-payment-cancel.component.html',
  styleUrls: ['./color-picker-payment-cancel.component.css']
})
export class ColorPickerPaymentCancelComponent implements OnInit {
  currentPlanTypeActive: SubscriptionPlanType = SubscriptionPlanType.BASIC;
  SubscriptionPlanType = SubscriptionPlanType;
  cancelConfirm = false;
  isSavingAccountDetails: boolean;
  accountError: string;
  constructor( private billingAccountService: PaymentService,
               private router: Router,
               private activatedRoute: ActivatedRoute ) {

  }

  ngOnInit(): void { // TODO: maybe add loading screen
    this.billingAccountService.getBillingAccountState().subscribe(accountState => {
      if (accountState.billingAccountState.accountExists ){
        this.currentPlanTypeActive = accountState.billingAccountState.paymentStatus;
      }
    });

  }
  cancelSubscription(): void {
    if (this.isSavingAccountDetails){
      return;
    }
    this.accountError = null;
    this.isSavingAccountDetails = true;
    this.billingAccountService.cancelSubscription().subscribe(canceledsubscription => {
      this.router.navigate(['../../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { success: 'Successfully canceled your subscription' }});
      this.isSavingAccountDetails = false;
    }, error => {
      this.isSavingAccountDetails = false;
      this.accountError = error.error || error;
    });
  }

}
