<form [formGroup]="this.billingInfoForm">

  <div class="slds-grid slds-gutters">


    <div class="slds-col">


      <div [class]="{'slds-has-error': full_name.invalid && (full_name.dirty || full_name.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="full_name">Name on card</label>
        <div class="slds-form-element__control">
          <input type="text" id="full_name" formControlName="full_name" required placeholder="Marty Hayward"
                 [attr.aria-describedby]="full_name.invalid && (full_name.dirty || full_name.touched)? 'form-error-00': null"
                 [attr.aria-invalid]="full_name.invalid && (full_name.dirty || full_name.touched)? 'true': null"
                 class="slds-input"/>
        </div>
        <div *ngIf="full_name.invalid && (full_name.dirty || full_name.touched)" class="slds-form-element__help"
             id="form-error-00">

          <div *ngIf="full_name.errors.required">
            Name on card is required.
          </div>
          <div *ngIf="full_name.errors.minlength">
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>


    <div class="slds-col">


      <div [class]="{'slds-has-error': email.invalid && (email.dirty || email.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="email">Email for receipt</label>
        <div class="slds-form-element__control">
          <input type="text" id="email" formControlName="email" required placeholder="marty.hayward@exzample.com"
                 [attr.aria-describedby]="email.invalid && (email.dirty || email.touched)? 'email-error': null"
                 [attr.aria-invalid]="email.invalid && (email.dirty || email.touched)? 'true': null"
                 class="slds-input"/>  <!-- TODO fix disabled error -->
        </div>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="slds-form-element__help"
             id="email-error">

          <div *ngIf="email.errors.required">
            Email is required.
          </div>
          <div *ngIf="email.errors.email">
            Must be a valid email
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="slds-grid slds-gutters">


    <div class="slds-col">


      <div [class]="{'slds-has-error': address.invalid && (address.dirty || address.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="address">Address</label>
        <div class="slds-form-element__control">
          <input type="text" id="address" formControlName="address" required placeholder="e.g. 101 main St"
                 [attr.aria-describedby]="address.invalid && (address.dirty || address.touched)? 'address-error': null"
                 [attr.aria-invalid]="address.invalid && (address.dirty || address.touched)? 'true': null"
                 class="slds-input"/>
        </div>
        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="slds-form-element__help"
             id="address-error">

          <div *ngIf="address.errors.required">
            Address is required.
          </div>
          <div *ngIf="address.errors.minlength">
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>


    <div class="slds-col">


      <div [class]="{'slds-has-error': city.invalid && (city.dirty || city.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="city">City</label>
        <div class="slds-form-element__control">
          <input type="text" id="city" formControlName="city" required placeholder="e.g. New York"
                 [attr.aria-describedby]="city.invalid && (city.dirty || city.touched)? 'city-error': null"
                 [attr.aria-invalid]="city.invalid && (city.dirty || city.touched)? 'true': null"
                 class="slds-input"/>
        </div>
        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="slds-form-element__help"
             id="city-error">

          <div *ngIf="city.errors.required">
            City is required.
          </div>
          <div *ngIf="city.errors.minlength">
            Name must be at least 4 characters long.
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="slds-grid slds-gutters">


    <div class="slds-col slds-size_1-of-2">


      <div [class]="{'slds-has-error': state.invalid && (state.dirty || state.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="state">State</label>
        <div class="slds-form-element__control">

            <div class="slds-select_container">
            <select ngl id="state" formControlName="state" required
                    [attr.aria-describedby]="state.invalid && (state.dirty || state.touched)? 'state-error': null"
                    [attr.aria-invalid]="state.invalid && (state.dirty || state.touched)? 'true': null"
                    class="slds-select">
              <option value="">Select State</option>
              <option *ngFor="let stateName of this.states" [value]="stateName.abbreviation">{{stateName.name}}</option>

            </select>
            </div>
        </div>
        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="slds-form-element__help"
             id="state-error">

          <div *ngIf="state.errors.required">
            State is required.
          </div>
          <div *ngIf="state.errors.minlength">
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>


    <div class="slds-col">


      <div [class]="{'slds-has-error': zip.invalid && (zip.dirty || zip.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="zip">Billing zip code</label>
        <div class="slds-form-element__control">
          <input type="text" id="zip" formControlName="zip" required placeholder="e.g. 10017"
                 [attr.aria-describedby]="zip.invalid && (zip.dirty || zip.touched)? 'zip-error': null"
                 [attr.aria-invalid]="zip.invalid && (zip.dirty || zip.touched)? 'true': null"
                 class="slds-input"/>
        </div>
        <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="slds-form-element__help"
             id="zip-error">

          <div *ngIf="zip.errors.required">
            Zip is required.
          </div>
          <div *ngIf="zip.errors.minlength">
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="slds-grid slds-gutters">


    <div class="slds-col slds-size_1-of-2">


      <div [class]="{'slds-has-error': country.invalid && (country.dirty || country.touched)}"
           class="slds-form-element slds-has-error">
        <label class="slds-form-element__label" for="country">Country</label>
        <div class="slds-form-element__control">
          <div class="slds-select_container">
          <select  id="country" formControlName="country" required
                 [attr.aria-describedby]="country.invalid && (country.dirty || country.touched)? 'country-error': null"
                 [attr.aria-invalid]="country.invalid && (country.dirty || country.touched)? 'true': null"
                   class="slds-select">
            <option value="">Select Country</option>
            <option *ngFor="let countryName of this.countries" [value]="countryName.abbreviation">{{countryName.name}}</option>

          </select>
          </div>
        </div>
        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="slds-form-element__help"
             id="country-error">

          <div *ngIf="country.errors.required">
            Country is required.
          </div>
          <div *ngIf="country.errors.minlength">
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
    </div>

  </div>
  <button class="slds-button slds-button_brand slds-m-top_medium" [disabled]="billingInfoForm.invalid" [attr.title]="billingInfoForm.valid? 'Next Step' : 'Must input valid fields' " (click)="saveBillingDetails()">
   Next
  </button>
  <div *ngIf="this.accountError" class="slds-text-heading_small slds-m-top_xxx-small">
        <span class="slds-text-color_error">
          <ng-container>{{this.accountError}}</ng-container>
        </span>
  </div>
</form>
