import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-picklist-dualing',
  templateUrl: './picklist-dualing.component.html',
  styleUrls: ['./picklist-dualing.component.css']
})
export class PicklistDualingComponent implements OnInit {
  picklistName: string;
  pickListOptions: PickListOptions[];

  constructor() { }

  ngOnInit(): void {
  }

}
interface PickListOptions {
  selected: boolean;
  movable: boolean;
  displayName: string;
  disabled: boolean;
  id: string;
}
