<div class="slds-m-around_x-small">
  <div *ngIf="!cancelConfirm">
<div class="slds-text-body_regular slds-m-top_xxx-small ">You are currently on the currently {{currentPlanTypeActive === 'premium'? 'premium': 'basic' }}. {{currentPlanTypeActive === 'premium'? 'Downgrade': 'Upgrade' }} now or cancel your plan below.</div>

<fieldset class="slds-form-element ">
  <label class=" slds-form-element__label">Select a plan</label>
  <div class="slds-form-element__control flex" style="">

    <div class="slds-visual-picker slds-visual-picker_small ml-auto"  routerLink="../">
      <input [checked]="currentPlanTypeActive === SubscriptionPlanType.BASIC" id="visual-picker-100" name="options" type="radio" value="visual-picker-100"/>
      <label for="visual-picker-100">
        <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center visual-picker-container" >
          <span>
            <span class="slds-text-heading_large">$5</span>
            <span class="slds-text-title">USD/user/month *</span>
          </span>
        </span>
        <span class="slds-visual-picker__body visual-picker-container" >
          <span class="slds-text-heading_small">Standard plan</span>
          <span class="slds-text-title slds-m-top_small slds-text-color--inverse-weak">Billed monthly</span>
          <span class="slds-text-title slds-text-color--inverse-weak">$5 per user</span>
          <span class="slds-text-title slds-text-color--inverse-weak">Basic plan up to 1 color palette with up to 10 brand colors</span>
          <button *ngIf="currentPlanTypeActive === 'premium'" class="slds-button slds-button_destructive slds-m-top_large">Downgrade to basic</button>

        </span>
        <span class="slds-icon_container slds-visual-picker__text-check">
          <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
          </svg>
        </span>
      </label>
    </div>



    <div class="slds-visual-picker slds-visual-picker_small mr-auto" routerLink="../">
      <input [checked]="currentPlanTypeActive === SubscriptionPlanType.PREMIUM" id="visual-picker-101" name="options" type="radio" value="visual-picker-101" />
      <label for="visual-picker-101">
        <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center visual-picker-container" >
          <span>
            <span class="slds-text-heading_large">$10</span>
            <span class="slds-text-title">USD/user/month*</span>
          </span>
        </span>
        <span class="slds-visual-picker__body visual-picker-container" >
          <span class="slds-text-heading_small">Premium plan</span>
          <span class="slds-text-body_regular slds-m-top_small slds-text-color--inverse-weak">Billed monthly</span>
          <span class="slds-text-body_regular slds-text-color--inverse-weak">$10 per user</span>
          <span
            class="slds-text-body_regular slds-text-color--inverse-weak">Premium plan with unlimited colors and palettes</span>
          <span *ngIf="currentPlanTypeActive === SubscriptionPlanType.BASIC">
            <span class="slds-m-top_small  slds-button slds-button--brand slds-text-body_small">
            Upgrade to premium
          </span>
          </span>
        </span>
        <span class="slds-icon_container slds-visual-picker__text-check">
          <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
          </svg>
        </span>
      </label>
    </div>

  </div>

</fieldset>
<div class="slds-text-body_regular slds-m-top_large slds-m-bottom--large">Are you sure you want to cancel your account? You will lose your ability to enforce brand standards with your Marketing Cloud users.</div>
<div class="flex slds-m-top_medium">
<button class="slds-button slds-button_neutral" routerLink="../../colorpickersettings">No, keep things as is</button>
<button class="slds-button slds-button_destructive ml-auto-btn block" (click)="cancelConfirm = true">Yes, cancel my account.</button>
</div>
  </div>
<div *ngIf="cancelConfirm">
  <div class="slds-text-body_regular slds-m-top_large slds-m-bottom--large">Are you sure you want to downgrade? You will lose your color palettes and you will need to set your custom colors. This will impact all users of the account.</div>
  <div class="flex slds-m-top_medium">
  <button class="slds-button slds-button_neutral" [disabled]="isSavingAccountDetails" (click)="cancelConfirm = false">No, cancel</button>
  <button class="slds-button slds-button_destructive ml-auto-btn block" (click)="cancelSubscription()">
    <div *ngIf="this.isSavingAccountDetails" style="position:relative; height:1.87rem; width: 2.3rem; z-index:0;">
      <ngl-spinner size="x-small" variant="inverse" alternativeText="Loading"></ngl-spinner>
    </div>
    <div *ngIf="!this.isSavingAccountDetails">Yes, downgrade</div>
  </button>
  </div>
  <div *ngIf="this.accountError" class="slds-text-heading_small  slds-m-top_large ">
    <span class="slds-text-color_error">
      <ng-container>{{this.accountError}}</ng-container>
    </span>
  </div>
</div>
</div>
