import {Component, OnInit} from '@angular/core';
import {InstallStateService} from '../install-state.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {InstalledAppService} from '../../services/installed-app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BlockAuthService} from '../../services/block-auth.service';

@Component({
  selector: 'app-color-picker-setup-p4',
  templateUrl: './color-picker-setup-p4.component.html',
  styleUrls: ['./color-picker-setup-p4.component.css']
})
export class ColorPickerSetupP4Component implements OnInit {
  accountError: string;
  apiInfoForm: FormGroup;
  isSavingAccountDetails: boolean;

  showModal = true;

  constructor(private installService: InstallStateService,
              private installedAppService: InstalledAppService,
              private router: Router,
              private blockAuthService: BlockAuthService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.installService.getPaymentStateLocationAndUpdate(4);
    const urlValidator = Validators.pattern(/^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/);
    this.apiInfoForm = new FormGroup({
      clientId: new FormControl('', [
        Validators.required,
        Validators.minLength(24),
        Validators.maxLength(24),
        Validators.pattern(/(^[A-Za-z\d]+)$/),
      ]),
      clientSecret: new FormControl('', [
        Validators.required,
        Validators.minLength(24),
        Validators.maxLength(24),
        Validators.pattern(/(^[A-Za-z\d]+)$/),

      ]),
      authBaseUrl: new FormControl('', [
        Validators.required,
        urlValidator,
      ]),
      restBaseUrl: new FormControl('', [
        Validators.required,
        urlValidator,
      ]),
      soapBaseUrl: new FormControl('', [
        Validators.required,
        urlValidator
      ]),

    });

    // this.paymentService.usePaymentMethodToSubscribe(this.installService.paymentMethod, this.installService.quantity)
    //   .subscribe(paymentResult => {
    //     console.log('Payment Proccessed');
    //   }, error => {
    //     if (error.error === undefined) {
    //       this.accountError = error;
    //       console.log(`Error Subscribing ${error}`);
    //     }else {
    //       this.accountError = error.error.message;
    //       console.log(`Error Subscribing ${error}`);
    //     }
    //   });
    this.showModal = false;
  }

  get clientId(): AbstractControl {
    return this.apiInfoForm.get('clientId');
  }

  get clientSecret(): AbstractControl {
    return this.apiInfoForm.get('clientSecret');
  }

  get authBaseUrl(): AbstractControl {
    return this.apiInfoForm.get('authBaseUrl');
  }

  get soapBaseUrl(): AbstractControl {
    return this.apiInfoForm.get('soapBaseUrl');
  }

  get restBaseUrl(): AbstractControl {
    return this.apiInfoForm.get('restBaseUrl');
  }


  populateEverything(): void {
    if (!this.soapBaseUrl.dirty && !this.restBaseUrl.dirty) {
      if (this.authBaseUrl.valid) {
        const baseSubdomain = /http(s|):\/\/([A-Za-z\d]+)\.auth\.marketingcloudapis\.com(|\/)/.exec(this.authBaseUrl.value)[2];
        this.soapBaseUrl.setValue(`https://${baseSubdomain}.soap.marketingcloudapis.com/`);
        this.restBaseUrl.setValue(`https://${baseSubdomain}.rest.marketingcloudapis.com/`);
        return;
      }
    }
    if (!this.authBaseUrl.dirty && !this.restBaseUrl.dirty) {
      if (this.restBaseUrl.valid) {
        const baseSubdomain = this.extractSubdomain(this.restBaseUrl.value);
        this.soapBaseUrl.setValue(`https://${baseSubdomain}.soap.marketingcloudapis.com/`);
        this.authBaseUrl.setValue(`https://${baseSubdomain}.rest.marketingcloudapis.com/`);
      }
    }
  }

  private extractSubdomain(stringUrl: string): string {
    return /http(s|):\/\/([A-Za-z\d]+)\.auth\.marketingcloudapis\.com(|\/)/.exec(stringUrl)[2];
  }

  previousButtonClicked(): void {
    this.router.navigate(['../3'], {relativeTo: this.activatedRoute});

  }

  nextButtonClicked(): void {
    this.isSavingAccountDetails = true;
    if (this.apiInfoForm.valid) {
      this.installedAppService.createNewInstalledApp({
        installed_id: this.installService.installedAppId,
        client_id: this.clientId.value,
        subdomain: this.extractSubdomain(this.authBaseUrl.value),
        client_secret: this.clientSecret.value,
      }).subscribe(value => {
        InstalledAppService.installedAppId = this.installService.installedAppId;
        const loginUrl = `https://${this.extractSubdomain(this.authBaseUrl.value)}.auth.marketingcloudapis.com/v2/authorize?response_type=code&client_id=${this.clientId.value}&redirect_uri=${encodeURIComponent(`${environment.baseOrigin}/IA/${this.installService.installedAppId}/api/blocksdk/access-token`)}`;
        const loginWindow = window.open(loginUrl, 'sf_login');
        this.blockAuthService.getAuthToken({subdomain: 'object', client_id: '32', redirect_url: 'fsdafkl'}).subscribe(valuetoken => {
          this.isSavingAccountDetails = false;
          loginWindow.close();
          this.router.navigate(['../5'], {relativeTo: this.activatedRoute});
        }, error => {
          loginWindow.close();
          this.isSavingAccountDetails = false;
          this.accountError = 'Unable to login to SalesForce';
        });

      }, error => {
        this.isSavingAccountDetails = false;
        this.accountError = 'Unable to save information at this time please try again';
      });
    } else {
      this.accountError = 'Please Correct Errors';
    }

  }
}
