// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  baseOrigin: 'https://colorpicker.e8enterprizes.com',
  production: false,
  stripe_pk: 'pk_test_51H3M8nFkFtnXwVKIaaCiGnrgrpCfgiRvsloWhxCU0KjbVfwR3pS6eiupv740tQiGVoqd3hmBVtLWBBji3d5E4YmC00ntBlhbDu',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
