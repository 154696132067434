<div class="content-block">
  <span class="large-Number ml3 slds-text-color--inverse slds-text-heading_large">3</span>
  <span class="small-des slds-text-color--inverse slds-text-heading_large">Add Components
    {{stage != 0 ? '...continued' : ''}}</span>
</div>
<div *ngIf="stage == 0" class="part-1">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Click on the 'Add Component' button</span>
        <img src="/assets/images/color-picker/setup/step-3-1.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Select "Custom Content Block" click next</span>
        <img src="/assets/images/color-picker/setup/step-3-2.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Name the block "MCToolbox-ColorPicker" use this URL, then press save:
          <br/>
          <code (focus)="$event.target.select()" >{{colorPickerUrl}}</code>
        </span>
        <img src="/assets/images/color-picker/setup/step-3-3.png">
      </div>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
    <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>

    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>

<div *ngIf="stage == 1" class="part-1">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Click on the 'Add Component' button</span>
        <img src="/assets/images/color-picker/setup/step-3-4.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Select "API Integration" click next</span>
        <img src="/assets/images/color-picker/setup/step-3-5.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Select "Web App" click next</span>
        <img src="/assets/images/color-picker/setup/step-3-6.png">
      </div>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
    <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>

    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>

<div *ngIf="stage == 2" class="part-1">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Copy and paste this URL into the URI field:
          <br/>
          <code (focus)="$event.target.select()" >{{accessTokenURL}}</code>
        </span>
        <img src="/assets/images/color-picker/setup/step-3-7.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">

      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Scroll down and add permissions for Users, and press save</span>
        <img src="/assets/images/color-picker/setup/step-3-8.png">
      </div>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
    <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>

    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>

<div *ngIf="stage == 3" class="part-1">
  <div class="slds-m-around--xx-large ">
    <div class="slds-grid slds-wrap slds-m-top--large ml4  slds-m-bottom--large">
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">
        <span class="slds-text-heading_medium">Highlight and copy the Client ID, Client Secret and Base URIs to your
          clip board
        </span>
        <img src="/assets/images/color-picker/setup/step-3-9.png">
      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">

      </div>
      <div class="slds-col slds-large-size_1-of-3" style="display: grid;">

      </div>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large slds-m-right_xx-large slds-m-left_xx-large flex">
    <button (click)="previousButtonClicked()" class="mr-auto block slds-button slds-button_brand">Previous</button>

    <button (click)="nextButtonClicked()" class="ml-auto block slds-button slds-button_brand">Next</button>
  </div>
</div>
