import {Component, OnInit} from '@angular/core';
import {AccountInputComponent} from '../account-input/account-input.component';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {IRegion, STATES} from '../../../../types/us-states';
import {countries} from '../../../../types/countries.enum';
import {BillingDetails} from './billing-details';


@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css']
})
export class BillingDetailsComponent  extends AccountInputComponent implements OnInit {
  billingInfoForm: FormGroup;
  accountError: string;


  states: IRegion[] = STATES;
  countries: IRegion[] = countries;

  constructor() {
    super();

    this.billingInfoForm  = new FormGroup({
      full_name: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      address: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      state: new FormControl('', [
        Validators.required,
      ]),
      city: new FormControl('', [
        Validators.required,
      ]),
      zip: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      country: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  get full_name(): AbstractControl { return this.billingInfoForm.get('full_name'); }
  get address(): AbstractControl { return this.billingInfoForm.get('address'); }
  get email(): AbstractControl { return this.billingInfoForm.get('email'); }
  get state(): AbstractControl { return this.billingInfoForm.get('state'); }
  get city(): AbstractControl { return this.billingInfoForm.get('city'); }
  get zip(): AbstractControl { return this.billingInfoForm.get('zip'); }
  get country(): AbstractControl { return this.billingInfoForm.get('country'); }
  ngOnInit(): void {
   // this.formComplete.emit(null);
  }

  saveBillingDetails(): void {
    const billingDetails: BillingDetails = {
      full_name: this.full_name.value,
      address_ln_1: this.address.value,
      email: this.email.value,
      state: this.state.value,
      city: this.city.value,
      zip: this.zip.value,
      country: this.country.value,
    };
    this.formComplete.emit(billingDetails);
  }
}
