import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ColorPickerEditorComponent} from './installed-app/color-picker-editor/color-picker-editor.component';
import {QuillModule} from 'ngx-quill';
import {HomeSplashComponent} from './home-splash/home-splash.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ColorPickerSettingsHomeComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-settings-home.component';
import {ColorPickerPalettesComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-palettes/color-picker-palettes.component';
import {NglAlertModule, NglModule} from 'ng-lightning';
import {ColorPickerPaymentComponent} from './installed-app/color-picker-settings/color-picker-payment/color-picker-payment.component';
import {AccountCreationComponent} from './installed-app/color-picker-settings/color-picker-payment/account-creation/account-creation.component';
import {BillingDetailsComponent} from './installed-app/color-picker-settings/color-picker-payment/billing-details/billing-details.component';
import {AccountPaymentComponent} from './installed-app/color-picker-settings/color-picker-payment/account-payment/account-payment.component';
import {NgxStripeModule} from 'ngx-stripe';
import {AccountInputComponent} from './installed-app/color-picker-settings/color-picker-payment/account-input/account-input.component';
import {InstalledAppComponent} from './installed-app/installed-app.component';
import {ColorPickerPaymentCancelComponent} from './installed-app/color-picker-settings/color-picker-payment-cancel/color-picker-payment-cancel.component';
import {ColorPickerInstallComponent} from './color-picker-install/color-picker-install.component';
import {ColorPickerSetupP1Component} from './color-picker-install/color-picker-setup-p1/color-picker-setup-p1.component';
import {ColorPickerSetupP2Component} from './color-picker-install/color-picker-setup-p2/color-picker-setup-p2.component';
import {ColorPickerSetupP3Component} from './color-picker-install/color-picker-setup-p3/color-picker-setup-p3.component';
import {ColorPickerSetupP4Component} from './color-picker-install/color-picker-setup-p4/color-picker-setup-p4.component';
import {ColorPickerSetupP5Component} from './color-picker-install/color-picker-setup-p5/color-picker-setup-p5.component';
import {ColorPickerPremiumViewerComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-premium-viewer/color-picker-premium-viewer.component';
import {ColorPickerPremiumSettingsComponent} from './installed-app/color-picker-settings/color-picker-premium-settings/color-picker-premium-settings.component';
import {environment} from "../environments/environment";
import {ColorPickerPaletteViewerComponent} from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-palette-viewer/color-picker-palette-viewer.component';
import {MergeFieldSettingsComponent} from './installed-app/color-picker-settings/color-picker-premium-settings/merge-field-settings/merge-field-settings.component';
import {PremiumFontSettingsComponent} from './installed-app/color-picker-settings/color-picker-premium-settings/premium-font-settings/premium-font-settings.component';
import {PreDefinedHeadingSettingsComponent} from './installed-app/color-picker-settings/color-picker-premium-settings/pre-defined-heading-settings/pre-defined-heading-settings.component';
import { PicklistDualingComponent } from './installed-app/color-picker-settings/color-picker-premium-settings/picklist-dualing/picklist-dualing.component';
import { PaletteNameEditorComponent } from './installed-app/color-picker-settings/color-picker-settings-home/color-picker-palettes/palette-name-editor/palette-name-editor.component';

@NgModule({
  declarations: [
    AppComponent,
    ColorPickerEditorComponent,
    HomeSplashComponent,
    ColorPickerSettingsHomeComponent,
    ColorPickerPalettesComponent,
    ColorPickerPaymentComponent,
    AccountCreationComponent,
    BillingDetailsComponent,
    AccountPaymentComponent,
    AccountInputComponent,
    InstalledAppComponent,
    ColorPickerPaymentCancelComponent,
    ColorPickerInstallComponent,
    ColorPickerSetupP1Component,
    ColorPickerSetupP2Component,
    ColorPickerSetupP3Component,
    ColorPickerSetupP4Component,
    ColorPickerSetupP5Component,
    ColorPickerPremiumViewerComponent,
    ColorPickerPremiumSettingsComponent,
    ColorPickerPaletteViewerComponent,
    MergeFieldSettingsComponent,
    PremiumFontSettingsComponent,
    PreDefinedHeadingSettingsComponent,
    ColorPickerPaletteViewerComponent,
    PicklistDualingComponent,
    PaletteNameEditorComponent,
  ],
  imports: [
    BrowserModule,
    QuillModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripe_pk),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NglAlertModule,
    NglModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
