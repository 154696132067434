import {Component, OnInit} from '@angular/core';
import {v4 as uuidV4} from 'uuid';
import {ActivatedRoute, Router} from '@angular/router';
import {InstallStateService} from '../install-state.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-color-picker-setup-p3',
  templateUrl: './color-picker-setup-p3.component.html',
  styleUrls: ['./color-picker-setup-p3.component.css']
})
export class ColorPickerSetupP3Component implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private installService: InstallStateService
  ) {
  }

  accountError: string;


  stage = 0;
  colorPickerUrl: string;
  accessTokenURL: string;
  private installedAppUUID: string;

  ngOnInit(): void {
    this.installService.getPaymentStateLocationAndUpdate(3);
    this.installedAppUUID = this.installService.installedAppId || uuidV4();
    this.installService.installedAppId = this.installedAppUUID;
    this.colorPickerUrl = `${environment.baseOrigin}/IA/${this.installedAppUUID}/colorpicker`;
    this.accessTokenURL = `${environment.baseOrigin}/IA/${this.installedAppUUID}/api/blocksdk/access-token`;
  }

  nextButtonClicked(): void {
    if (this.stage < 3) {
      this.stage++;
    } else {
      this.router.navigate(['../4'], {relativeTo: this.activatedRoute});

    }
  }

  previousButtonClicked(): void {
    if (this.stage > 0) {
      this.stage--;
    } else {
      this.router.navigate(['../2'], {relativeTo: this.activatedRoute});

    }
  }


}
