<div class="content-block">
  <p class="slds-text-color--inverse slds-m-bottom--large slds-text-heading_large">Five steps to install the Color
    Picker
    <br/>
    app for Salesforce Marketing Cloud
  </p>
  <div class=" slds-grid slds-wrap slds-m-top--large  slds-m-bottom--large">
    <div class="slds-col slds-large-size_1-of-5">
      <span class="large-Number slds-text-color--inverse slds-text-heading_large">1</span>
      <span class="small-des slds-text-color--inverse slds-text-body_regular">Create an installed package</span>
    </div>
    <div class="slds-col slds-large-size_1-of-5">
      <span class="large-Number slds-text-color--inverse slds-text-heading_large">2</span>
      <span class="small-des slds-text-color--inverse slds-text-body_regular">Sign up for an account</span>
    </div>
    <div class="slds-col slds-large-size_1-of-5">
      <span class="large-Number slds-text-color--inverse slds-text-heading_large">3</span>
      <span class="small-des slds-text-color--inverse slds-text-body_regular">Add components</span>
    </div>
    <div class="slds-col slds-large-size_1-of-5">
      <span class="large-Number slds-text-color--inverse slds-text-heading_large">4</span>
      <span class="small-des slds-text-color--inverse slds-text-body_regular">Submit the API info</span>
    </div>
    <div class="slds-col slds-large-size_1-of-5">
      <span class="large-Number slds-text-color--inverse slds-text-heading_large">5</span>
      <span class="small-des slds-text-color--inverse slds-text-body_regular">Billing confirmation</span>
    </div>
  </div>
  <div class="slds-m-top_x-large slds-m-bottom--x-large">
    <button class="mx-auto block slds-button ex-l-button slds-button_brand" routerLink="setup/1">Get Started</button>
  </div>
</div>
