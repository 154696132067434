<div class="page-header pb5 pb-0">
  <div class="max-width-4 mx-auto center ">
  <div class="clearfix ">
    <div class="col col-6 my-auto inline-block left-align">
      <div class="display-4 h1">Colorpicker</div>
      <p class="lead">
        This awesome content block app for Salesforce Marketing Cloud allows your company to use brand-approved colors. No longer worry about marketing cloud users picking the wrong brand color for their emails
        and landing pages.</p>
      <a routerLink="/colorpicker"><button id="btn1" class="btn style2" data-toggle="button"><b>Learn more</b></button></a>
    </div>
    <div class="col col-6 ">
      <img class="img-fluid w-75 h-auto bg-transparent img" src="assets/images/icon.png">
    </div>
  </div>
</div>
</div>
