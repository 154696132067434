import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../services/payment.service';

@Component({
  selector: 'app-home-splash',
  templateUrl: './home-splash.component.html',
  styleUrls: ['./home-splash.component.css']
})
export class HomeSplashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
