import {Component, OnInit} from '@angular/core';
import {BusinessUnitService} from '../../../services/business-unit.service';
import {BlockAuthService} from '../../../services/block-auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
import {SubscriptionPlanType} from 'src/app/types/plan-type.enum';
import {v4 as uuidV4} from 'uuid';
@Component({
  selector: 'app-color-picker-settings-home',
  templateUrl: './color-picker-settings-home.component.html',
  styleUrls: ['./color-picker-settings-home.component.css']
})
export class ColorPickerSettingsHomeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private businessUnitService: BusinessUnitService,
              private blockAuthService: BlockAuthService,
              private router: Router,

              private billingAccountService: PaymentService)
  { }

  currentPlanTypeActive: any;
  isLoadingAuth = true;
  isAdmin: boolean;

  SubscriptionPlanType = SubscriptionPlanType;
uuid = uuidV4();
  successParam: string;
  errorParam: string;
  authError: string;

  ngOnInit(): void {

    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      this.successParam = paramMap.get('success');
      this.errorParam = paramMap.get('error');
    });
    const sub = this.blockAuthService.getAuthToken().subscribe(() => {
      this.businessUnitService.hasAdminAccess().subscribe(returnedValue => {
        this.isAdmin = returnedValue.hadAdminAccess;
        this.isLoadingAuth = false;
      });
      this.billingAccountService.getBillingAccountState().subscribe(accountState => {
        if (accountState.billingAccountState.accountExists ) {
          this.currentPlanTypeActive = accountState.billingAccountState.paymentStatus;
        }
        if ((!accountState.billingAccountState.accountExists || accountState.billingAccountState.paymentStatus === `free`)
          && !this.successParam) {
          this.router.navigate(['../colorpayment'], {relativeTo: this.activatedRoute});
        }
      });
    }, error => {
      this.authError = error.error_description;
      this.isLoadingAuth = false;
    });
    setTimeout(() => {
      this.isLoadingAuth = false;
      sub.unsubscribe();
    }, 6000);

  }


}
