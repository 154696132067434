import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {BlockAuthService} from './block-auth.service';
import {HttpClient} from '@angular/common/http';
import {ColorSwatch} from '../types/color-swatch';
import {ColorPalette} from '../types/color-palette';
import {InstalledAppService} from './installed-app.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {

  constructor(private http: HttpClient, private blockAuthService: BlockAuthService) { }


  public  getBusinessUnit(): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/business-unit/status`,
        this.blockAuthService.injectAuth());
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public getColorPalettes(): Observable<ColorPalette[]>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<ColorPalette[]>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/business-unit/color-palettes`,
        this.blockAuthService.injectAuth());
    }else {
      return new Observable<ColorPalette[]>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public saveDefaultColorSwatches(colorSwatches: ColorSwatch[]): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/business-unit/default/color-swatches`, // TODO Change url
        this.blockAuthService.injectAuth(colorSwatches));
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }

  public saveColorSwatches(colorSwatches: ColorSwatch[], colorPaletteName: string, paletteId: string): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/business-unit/save/color-swatches`,
        this.blockAuthService.injectAuth({color_palette_name: colorPaletteName, swatches: colorSwatches}), {params: {cp_id: paletteId}});
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }
  public hasAdminAccess(): Observable<any>{
    if (this.blockAuthService.isAuthenticated()){
      return this.http.post<any>(`${environment.baseOrigin}/IA/${InstalledAppService.installedAppId}/api/business-unit/admin-access`,
        this.blockAuthService.injectAuth());
    }else {
      return new Observable<any>(subscriber => subscriber.error('User is not authenticated'));
    }
  }
}
