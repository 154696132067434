import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../services/payment.service';
import {InstallStateService} from '../install-state.service';
import {SubscriptionPlanType} from '../../types/plan-type.enum';

@Component({
  selector: 'app-color-picker-setup-p5',
  templateUrl: './color-picker-setup-p5.component.html',
  styleUrls: ['./color-picker-setup-p5.component.css']
})
export class ColorPickerSetupP5Component implements OnInit {
  quantity = 100;
  math = Math;
  processingInformation = false;
  accountError: string;
  isSavingAccountDetails: boolean;
  hasBillingAccount: boolean;
  planType: SubscriptionPlanType = SubscriptionPlanType.BASIC;
  paymentProcessed: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private installService: InstallStateService
  ) {
  }

  ngOnInit(): void {
    this.installService.getPaymentStateLocationAndUpdate(5);
    this.quantity = this.installService.quantity;
    this.planType = this.installService.planType;
    this.paymentService.getBillingAccountState().subscribe(accountState => {
      this.hasBillingAccount = accountState.billingAccountState.accountExists;
      if (this.hasBillingAccount){
        console.log('ERROR: Can only have 1 install of the app per Business Unit');
        this.accountError = 'Can only have 1 install of this app per Business Unit. Please contact info@e8enterprizes.com for more info';

      }
    }, error => {
      console.log(JSON.stringify(error));
      this.accountError = 'Error getting account infromation';
    });

  }

  previousButtonClicked(): void {
    if (!this.paymentProcessed) {
      this.router.navigate(['../4'], {relativeTo: this.activatedRoute});
    }else {
      this.router.navigate(['/']);
    }
  }

  nextButtonClicked(): void {
    this.accountError = undefined;
    this.processingInformation = true;
    if (!this.hasBillingAccount) {

      this.paymentService.createSetBillingAccount(this.installService.billingAccount)
        .subscribe(value => {
          this.successfulAccountSubmit();
        }, error => {
          this.processingInformation = false;
          this.accountError = 'Unable to save account info';
        });
    } else {
      console.log('ERROR: Can only have 1 install of the app per Business Unit');
      this.accountError = 'Can only have 1 install of the app per Business Unit. Please contact mailto:info@e8enterprizes.com ';
      this.processingInformation = false;
    }

  }

  private successfulAccountSubmit(): void {
    this.paymentService.usePaymentMethodToSubscribe(this.installService.paymentMethod, this.quantity, this.planType)
      .subscribe(paymentResult => {
        this.paymentProcessed = true;
        this.processingInformation = false;
        this.installService.clearState();
        console.log('Payment Processed');
      }, error => {
        this.processingInformation = false;
        if (error.error === undefined) {
          this.accountError = error;
          console.log(`Error Subscribing ${error}`);
        } else {
          this.accountError = error.error.message;
          console.log(`Error Subscribing ${error}`);
        }
      });
  }
}
