<div aria-labelledby="picklist-group-label" class="slds-form-element" role="group">
  <span class="slds-form-element__label slds-form-element__legend" id="picklist-group-label">Select Options</span>
  <div class="slds-form-element__control">
    <div class="slds-dueling-list">
      <div aria-live="assertive" class="slds-assistive-text" ></div>
      <div class="slds-assistive-text" id="option-drag-label">Press space bar when on an item, to move it within the list. Cmd/Ctrl plus left and right arrow keys, to move items between lists.</div>
      <div class="slds-dueling-list__column slds-dueling-list__column_responsive">
        <span class="slds-form-element__label" id="label-105">{{picklistName}}</span>
        <div class="slds-dueling-list__options">
          <ul aria-describedby="option-drag-label" aria-labelledby="label-105" aria-multiselectable="false" class="slds-listbox slds-listbox_vertical" role="listbox">


            <li *ngFor="let picklistItem of pickListOptions" class="slds-listbox__item" role="presentation">
              <div aria-selected="false" class="slds-listbox__option slds-listbox__option_plain slds-media slds-media_small slds-media_inline" draggable="false" role="option" tabindex="0">
                <span class="slds-media__body">
                <span class="slds-truncate" title="Arabic">{{picklistItem.displayName}}</span>
                </span>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div class="slds-dueling-list__column">
        <button class="slds-button slds-button_icon slds-button_icon-container" title="Move Selection to Selected Languages">
          <svg aria-hidden="true" class="slds-button__icon">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#right"></use>
          </svg>
          <span class="slds-assistive-text">Move Selection to Selected Languages</span>
        </button>
        <button class="slds-button slds-button_icon slds-button_icon-container" title="Move Selection to Available Languages">
          <svg aria-hidden="true" class="slds-button__icon">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#left"></use>
          </svg>
          <span class="slds-assistive-text">Move Selection to Available Languages</span>
        </button>
      </div>
      <div class="slds-dueling-list__column slds-dueling-list__column_responsive">
        <span class="slds-form-element__label" id="label-106">Selected Languages</span>
        <div class="slds-dueling-list__options">
          <ul aria-describedby="option-drag-label" aria-labelledby="label-106" aria-multiselectable="true" class="slds-listbox slds-listbox_vertical" role="listbox">
            <li class="slds-listbox__item" role="presentation">
              <div aria-selected="false" class="slds-listbox__option slds-listbox__option_plain slds-media slds-media_small slds-media_inline" draggable="true" role="option" tabindex="0">
          <span class="slds-media__body">
          <span class="slds-truncate" title="Chinese">Chinese</span>
          </span>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
