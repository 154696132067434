<div aria-selected="true" class="slds-cell-edit" role="gridcell">
          <span (click)="isPopupVisible=true" class="slds-grid slds-grid_align-spread">
            <span class="slds-truncate" title="Acme">{{paletteName}}</span>
            <button class="slds-button slds-button_icon slds-cell-edit__button slds-m-left_x-small" tabindex="0" title="Color Palette Name">
              <svg class="slds-button__icon slds-button__icon_hint slds-button__icon_edit" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
              </svg>
              <span class="slds-assistive-text">Current Color Palette Name</span>
            </button>
          </span>
  <section *ngIf="isPopupVisible" aria-describedby="cp-name" class="slds-popover slds-popover slds-popover_edit" role="dialog" style="position:absolute;top:0;left:0.0625rem">
    <div class="slds-popover__body" id="cp-name">
      <div class="slds-form-element slds-grid slds-wrap">
        <label class="slds-form-element__label slds-form-element__label_edit slds-no-flex" for="palette-name">
          <span class="slds-assistive-text">Color Palette Name</span>
        </label>
        <div class="slds-form-element__control slds-grow">
          <input type="text" [(ngModel)]="paletteName" (keyup.enter)="submitName()" (keyup.escape)="submitName()"  (blur)="submitName()"  class="slds-input" id="palette-name"  />
        </div>
      </div>
    </div>
  </section>
</div>
