import {Component, OnInit} from '@angular/core';

import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountInputComponent} from '../account-input/account-input.component';
import {PaymentService} from '../../../../services/payment.service';
import {BlockAuthService} from '../../../../services/block-auth.service';

@Component({
  selector: 'app-account-creation',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.css'],
})
export class AccountCreationComponent extends AccountInputComponent implements OnInit {

  accountForm: FormGroup;
  isSavingAccountDetails: boolean;
  accountError: string;
  hasBillingAccount: boolean;

  constructor(private paymentService: PaymentService, private blockAuthService: BlockAuthService) {
    super();
    this.accountForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
    });

  }

  ngOnInit(): void {

    this.blockAuthService.getAuthToken().subscribe(() => {
      this.paymentService.getBillingAccountState().subscribe(accountState => {
        this.hasBillingAccount = accountState.billingAccountState.accountExists;
      }, error => {
        this.failureAccountSubmit(error);
      });
      this.paymentService.getBillingAccountInfo().subscribe(billingAccount => {
        if (billingAccount) {
          this.firstName.setValue(billingAccount.firstName);
          this.lastName.setValue(billingAccount.lastName);
          this.email.setValue(billingAccount.email);
        }
      }, error => {
        this.failureAccountSubmit(error);
      });
    }, error => {
      this.failureAccountSubmit(error);
    });


  }

  get firstName(): AbstractControl {
    return this.accountForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.accountForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.accountForm.get('email');
  }

  saveAccountDetails(): void {
    this.accountError = null;
    this.isSavingAccountDetails = true;
    const accountInfo = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value
    };
    if (!this.hasBillingAccount) {

      this.paymentService.createSetBillingAccount(accountInfo)
        .subscribe(value => {
          this.successfulAccountSubmit(accountInfo);
        }, error => {
          this.failureAccountSubmit(error);
        });
    } else {

      this.paymentService.updateBillingAccount(accountInfo)
        .subscribe(value => {
          this.successfulAccountSubmit(accountInfo);
        }, error => {
          this.failureAccountSubmit(error);
        });
    }

  }

  private failureAccountSubmit(error): void{
    this.isSavingAccountDetails = false;
    console.log(JSON.stringify(error));
    this.accountError = error; // TODO correct error reporting
    this.formError.emit(error);
  }

  private successfulAccountSubmit(accountInfo): void {
    this.isSavingAccountDetails = false;
    this.formComplete.emit(accountInfo);
  }
}
