import {Component, OnInit} from '@angular/core';
import {BlockAuthService} from '../../../../services/block-auth.service';
import {BusinessUnitService} from '../../../../services/business-unit.service';
import {ColorSwatch, rgbToHex} from '../../../../types/color-swatch';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../../services/payment.service';
import {SubscriptionPlanType} from '../../../../types/plan-type.enum';
import {ColorPalette} from '../../../../types/color-palette';

@Component({
  selector: 'app-color-picker-palettes',
  templateUrl: './color-picker-palettes.component.html',
  styleUrls: ['./color-picker-palettes.component.css']
})
export class ColorPickerPalettesComponent implements OnInit {
  private isAdmin: boolean;
  private isLoadingAuth: boolean;
  isLoadingColors = true;
  isSavingColors = false;
  textColorsSwatches: ColorSwatch[] = [];
  hexColors: string [] = [];
  authError: string;
  private colorPaletteId: string;
  accountPlan: SubscriptionPlanType;
  SubscriptionPlanType = SubscriptionPlanType;
  paletteName: string;

  constructor(private activatedRoute: ActivatedRoute ,
              private router: Router,
              private blockAuthService: BlockAuthService,
              private paymentService: PaymentService,
              private businessUnitService: BusinessUnitService) { }

  ngOnInit(): void {
    this.colorPaletteId = undefined;
    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      this.colorPaletteId = paramMap.get('cp_id');
    });
    const sub = this.blockAuthService.getAuthToken().subscribe(() => {
      this.businessUnitService.hasAdminAccess().subscribe(returnedValue => {
        this.isAdmin = returnedValue.hadAdminAccess;
        this.isLoadingAuth = false;
      });
      this.paymentService.getBillingAccountState().subscribe(accountState => {
         this.accountPlan = accountState.billingAccountState.paymentStatus;

      });
      this.businessUnitService.getColorPalettes().subscribe(returnedTextColors => {
        let selectedColorPalette: ColorPalette;
        if (this.colorPaletteId){
          selectedColorPalette = returnedTextColors.find(value => {if (value.id === this.colorPaletteId) { return value; }});
          if (!selectedColorPalette){ // Create new Palette
            selectedColorPalette = {
              id: this.colorPaletteId,
              color_swatches : [
              this.randomColorSwatch(),
              this.randomColorSwatch(),
              this.randomColorSwatch(),
              this.randomColorSwatch()]
              ,
              palette_name : `Palette ${this.getRandomInt(200)}`
          };
          }
        }else {
           selectedColorPalette = returnedTextColors[0];
        }

        this.textColorsSwatches = selectedColorPalette.color_swatches;
        this.paletteName = selectedColorPalette.palette_name;

        // Create variables for color picker
        for (const colorSwatch of this.textColorsSwatches){
          this.hexColors.push(this.colorSwatchToHex(colorSwatch));
        }
        this.isLoadingColors = false;
      });
    }, error => {
      this.authError = error.error_description;
      this.isLoadingAuth = false;
      this.isLoadingColors = false;
    });

    setTimeout(() => {
      this.isLoadingAuth = false;
      this.isLoadingColors = false;
      sub.unsubscribe();
    }, 7000);
  }
  saveColorPalette(): void {
    // Create variables for color picker
    for (let i = 0; i < this.hexColors.length; i ++){
      const cs = this.textColorsSwatches[i];
      const hexRep = this.hexToRgb(this.hexColors[i]);
      cs.r = hexRep.r;
      cs.g = hexRep.g;
      cs.b = hexRep.b;
    }
    if (this.accountPlan === SubscriptionPlanType.PREMIUM){
      this.businessUnitService.saveColorSwatches(this.textColorsSwatches, this.paletteName , this.colorPaletteId).subscribe(data => {
        this.router.navigate(['../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { success: 'Success, your custom colors have been saved.' }});
      }, error => {
        this.router.navigate(['../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { error: `Error: we are unable to save colors please try again` }});
      });
    } else {
    this.businessUnitService.saveDefaultColorSwatches(this.textColorsSwatches).subscribe(data => {
      this.router.navigate(['../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { success: 'Success, your custom colors have been saved.' }});
    }, error => {
      this.router.navigate(['../colorpickersettings'], {relativeTo: this.activatedRoute , queryParams: { error: `Error: we are unable to save colors please try again` }});
    });
    }
  }

   getRandomInt(max): number {
    return Math.floor(Math.random() * Math.floor(max));
  }
  colorSwatchToHex(textColors: ColorSwatch): string {
    return rgbToHex(textColors.r, textColors.g, textColors.b);
  }

  toggleColorTypeSelected(buttonTypePressed: string, color: ColorSwatch): void {
    if (color.text_types.includes(buttonTypePressed)) {
      color.text_types = color.text_types.filter(value => value !== buttonTypePressed);
    }else {
      color.text_types.push(buttonTypePressed);
    }
  }

  deleteColor(color: ColorSwatch, hexColor: string): void {
    this.textColorsSwatches = this.textColorsSwatches.filter(colors => colors !== color);
    this.hexColors = this.hexColors.filter(colors => colors !== hexColor);
  }
   randomColorSwatch(): ColorSwatch {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    return {
      text_types: [],
      r: o(r() * s),
      g: o(r() * s),
      b: o(r() * s),
      id: ''
    };
  }

  hexToRgb(hex): any{
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  isColorInValid(value: string): boolean{
    return this.hexColors.filter((val) => (val === value)).length > 1;
  }

    addColor(): void {
      const cs = this.randomColorSwatch();
      this.textColorsSwatches.push(cs);
      this.hexColors.push(this.colorSwatchToHex(cs));
    }
}
